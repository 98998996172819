<template>
  <div class="container">
    <div class="seat-content no-margin no-padding">
      <div class="grid-container" :style="{ gridTemplateColumns: `repeat(${maxX}, 1fr)`, gridTemplateRows: `repeat(${maxY}, 1fr)` }">
        <div
          v-for="(lot, index) in parkingLots.flat()" 
          :key="index"
          class="grid-item"
          :style="{
            gridRow: lot.x + 1, 
            gridColumn: lot.y + 1,
            width: `${100 / maxX}-10vw`, 
            height: `${100 / maxX}-10vw`, 
          }" 
          :class="{ 'selected': isSelectedLot(lot) }">
          <img 
          v-if="lotIcon(lot)" 
          :src="lotIcon(lot)" 
          class="item-src"
          :alt="`${lot.type} Icon`" />
          <img
            v-if="isSelectedLot(lot)"
            :src="selectedIcon"
            class="selected-overlay"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import ExitIcon from '../assets/ic_entry_exit.svg';
import noIcon from '../assets/Group 143726377.svg'; // 예약 불가 상태 아이콘
import eastIcon from '../assets/ic_patch_east.svg'; // EAST 상태 아이콘
import westIcon from '../assets/ic_patch_west.svg'; // WEST 상태 아이콘
import southIcon from '../assets/ic_patch_south.svg'; // SOUTH 상태 아이콘
import northIcon from '../assets/ic_patch_north.svg'; // NORTH 상태 아이콘
import selectedIcon from '../assets/ic_selected.svg';

export default {
  data() {
    return {
      parkingLots: [],
      maxX: 0,
      maxY: 0,
      selectedLot: null,
      selectedIcon: selectedIcon,
    };
  },
  computed: {
    id() {
      return this.$route.query.id;
    },
    authToken() {
      return this.$route.query.authToken;
    },
  },
  methods: {
    formatAvailableTime(start, end) {
      if (start && end) {
        return `${start.slice(0, 5)} ~ ${end.slice(0, 5)}`;
      } else {
        return "";  // 시간이 없는 경우 표시
      }
    },
    isSelectedLot(lot) {
      return this.selectedLot && this.selectedLot.x === lot.x && this.selectedLot.y === lot.y;
    },
    checkReserved() {
      this.selectedLot = {
        x: parseInt(this.$route.query.x),
        y: parseInt(this.$route.query.y)
      } 
    },
    async fetchParkingLots() {
      if (!this.id || !this.authToken) return;
      const apiUrl = `https://api.simpleparking.co.kr/v1/parking/${this.id}/seats`;

      try {
        const response = await axios.get(apiUrl, {
          headers: {
            Authorization: `Bearer ${this.authToken}`,
          },
        });
        const data = response.data.list;
        
        this.parkingLots = data;
        this.maxX = Math.max(...data.flat().map(lot => lot.x)) + 1;
        this.maxY = Math.max(...data.flat().map(lot => lot.y)) + 1;
      } catch (error) {
        console.error('주차장 정보를 불러오는 중 오류 발생:', error);
      }
    },
    lotIcon(lot) {
      if (lot.type === 'DEVICE') {
        switch (lot.batch) {
          case 'EAST':
            return eastIcon;
          case 'WEST':
            return westIcon;
          case 'SOUTH':
            return southIcon;
          case 'NORTH':
            return northIcon;
          default:
            return null;
        }
      } else if (lot.type === 'SEAT') {
        return noIcon;  // SEAT일 때 예약 불가 아이콘
      } else if (lot.type === 'ROAD') {
        return null; // ROAD는 아이콘 없음
      } else if (lot.type === 'ENTRANCE') {
        return ExitIcon;
      }
      return null;
    },
  },
  mounted() {
    this.fetchParkingLots();    
    this.checkReserved();
  },
};
</script>

<style scoped>
/* 여기에 필요한 스타일 추가 */
.container {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
}

.grid-container {
  display: grid;
  width: 100%;
  height: fit-content;
  padding: 5px;
}

.grid-item {
  min-height: 80px; 
  min-width: 80px; 
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.item-src {
  height: 95%; /* 각 칸의 높이 */
  width: 95%; /* 각 칸의 너비 */
}

.lotDiv {
  display: inline-block;
}


.parkingLot {
  background-color: white;
  border: none;
  cursor: pointer;
  position: relative;
}

.selected-overlay {
  position: absolute;
  top: 0;  /* 상단에 위치 */
  left: 0;  /* 좌측에 위치 */
  height: 100%;  /* grid-item 크기에 맞게 조정 */
  width: 100%;  /* grid-item 크기에 맞게 조정 */
  z-index: 2;
}

.item-desc {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  top: 0;  /* 상단에 위치 */
  left: 0;  /* 좌측에 위치 */
  height: 100%;  /* grid-item 크기에 맞게 조정 */
  width: 100%;  /* grid-item 크기에 맞게 조정 */
  z-index:1;
  padding: .3rem;
}

.desc-text{
  text-align: center;
  font-size: x-small;
}
</style>
