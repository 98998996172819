<template>
    <div>
        <h4 class="listTitle">공지사항</h4>

        <div class="notice-wrapper">
            <div class="btn-wrapper">
                <button class="black-btn" v-if="!isEditMode" @click="navigateToNoticeBoard">←</button>
                <button class="blue-btn" @click="toggleEditMode">{{ isEditMode ? '글 저장' : '글 수정' }}</button>
                <button class="red-btn" v-if="isEditMode" @click="showDeleteModal">삭제</button>
                <button class="black-btn" v-if="isEditMode" @click="showCancelEditModal">취소</button>
            </div>

            <div>
                <input class="textarea-input" type="text" placeholder="제목" v-model="noticeData.title"
                    :disabled="!isEditMode" :class="{ 'no-cursor': !isEditMode }" />
                <div class="textarea-fixed">
                    <input type="checkbox" v-model="noticeData.isFixed" :disabled="!isEditMode" /><label
                        for="isFixed">상단고정</label>
                </div>
                <div class="textarea-container">
                    <div class="label">본문내용</div>
                    <textarea class="input-area" v-model="noticeData.content" :disabled="!isEditMode"
                        :class="{ 'no-cursor': !isEditMode }"></textarea>
                </div>
            </div>
        </div>

        <!-- 수정 모달 -->
        <b-modal v-model="수정모달창열렸니" hide-footer hide-header centered>
            <div class="modalContents">
                <div class="modalImageWrapper">
                    <img src="@/assets/icons/Vector.svg" alt="" class="modalCar" />
                </div>
                <div class="modalTextWrapper">
                    <p class="mainAnswer">공지사항을 수정하시겠습니까?</p>
                </div>
                <div class="modalBtn">
                    <b-button class="modal-blue-btn" @click="confirmSaveChanges">네</b-button>
                    <b-button class="modal-red-btn" @click="수정모달창열렸니 = false">아니오</b-button>
                </div>
            </div>
        </b-modal>

        <!-- 수정취소 모달 -->
        <b-modal v-model="수정취소모달창열렸니" hide-footer hide-header centered>
            <div class="modalContents">
                <div class="modalImageWrapper">
                    <img src="@/assets/icons/Vector.svg" alt="" class="modalCar" />
                </div>
                <div class="modalTextWrapper">
                    <p class="mainAnswer">공지사항 수정을 취소하시겠습니까?</p>
                    <p>변경된 사항은 저장되지 않습니다.</p>
                </div>
                <div class="modalBtn">
                    <b-button class="modal-blue-btn" @click="confirmCancelEdit">네</b-button>
                    <b-button class="modal-red-btn" @click="수정취소모달창열렸니 = false">아니오</b-button>
                </div>
            </div>
        </b-modal>

        <!-- 삭제 모달 -->
        <b-modal v-model="삭제모달창열렸니" hide-footer hide-header centered>
            <div class="modalContents">
                <div class="modalImageWrapper">
                    <img src="@/assets/icons/Vector.svg" alt="" class="modalCar" />
                </div>
                <div class="modalTextWrapper">
                    <p class="mainAnswer">공지사항을 삭제하시겠습니까?</p>
                    <p>삭제된 공지사항은 복구되지 않습니다.</p>
                </div>
                <div class="modalBtn">
                    <b-button class="modal-blue-btn" @click="confirmDelete">네</b-button>
                    <b-button class="modal-red-btn" @click="삭제모달창열렸니 = false">아니오</b-button>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    props: ["id"],
    data() {
        return {
            수정모달창열렸니: false,
            수정취소모달창열렸니: false,
            삭제모달창열렸니: false,
            noticeData: null,
            isEditMode: false,
            originalData: null,
        };
    },
    created() {
        if (this.$route.query.itemData) {
            this.noticeData = JSON.parse(this.$route.query.itemData);
            this.originalData = JSON.parse(this.$route.query.itemData);  // 원본 데이터 초기화
        } else {
            this.$router.push("/noticeBoard");
        }
    },
    methods: {
        ...mapActions(['updateNotice', 'deleteNotice']),
        navigateToNoticeBoard() {
            this.$router.push('/noticeBoard');
        },
        toggleEditMode() {
            if (this.isEditMode) {
                this.수정모달창열렸니 = true; // 저장 모달 열기
            } else {
                this.isEditMode = true;
            }
        },
        async confirmSaveChanges() {
            this.수정모달창열렸니 = false;
            this.isEditMode = false;

            try {
                await this.updateNotice({ id: this.noticeData.id, updateData: this.noticeData });
                await this.$store.dispatch('getNoticeBoard'); // 공지사항 목록 업데이트
                this.$router.push('/noticeBoard'); // 저장 후 공지사항 목록으로 이동
            } catch (error) {
                console.error('공지사항 수정 중 오류 발생:', error);
            }
        },
        showCancelEditModal() {
            this.수정취소모달창열렸니 = true;
        },
        confirmCancelEdit() {
            this.noticeData = JSON.parse(JSON.stringify(this.originalData));  // 원본 데이터로 복원
            this.isEditMode = false;
            this.수정취소모달창열렸니 = false;
        },
        showDeleteModal() {
            this.삭제모달창열렸니 = true;
        },
        async confirmDelete() {
            try {
                await this.deleteNotice(this.noticeData.id); // 공지사항 삭제
                this.$router.push('/noticeBoard'); // 삭제 후 공지사항 목록으로 이동
            } catch (error) {
                console.error('공지사항 삭제 중 오류 발생:', error);
            }
        }
    }
};
</script>

<style>
.label {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.625rem;
    border-right: 1px solid #e0e0e0;
}

.notice-wrapper {
    display: flex;
    flex-direction: column;
    margin-left: 6.25rem;
    padding-right: 6.25rem;
}

.btn-wrapper {
    margin-bottom: 1.25rem;
    display: flex;
    gap: 0.625rem;
}

.textarea-container {
    height: 50vh;
    display: flex;
    flex-direction: row;
    border: 1px #E0E0E0 solid;
    border-radius: 1rem;
    background-color: white;
}

.input-area {
    flex: 9;
    outline: none;
    border: none;
    padding: 0.625rem;
    margin: 0.625rem;
}

.input-area:disabled {
    background-color: white;
}

.textarea-input {
    width: 100%;
    height: 5vh;
    border: 1px #e0e0e0 solid;
    border-radius: 1rem;
    outline: none;
    padding: 1.25rem;
}

.textarea-input:disabled {
    background-color: white;
}

.textarea-fixed {
    display: flex;
    gap: 0.625rem;
    margin-top: 0.625rem;
    margin-bottom: 1.25rem;
    margin-left: 0.625rem;
}

/* 커서 숨기기 */
.no-cursor {
    pointer-events: none;
    caret-color: transparent;
}
</style>
