<template>
  <!-- 주차창 제보내역 중 답변완료된 테이블 -->
  <div>
    <h4 class="listTitle">주차장 제보 내역</h4>

    <div class="tip-btn">
      <button class="black-btn" v-if="!isEditing" @click="goToLotTipPage">←</button>
    </div>

    <div class="tip-content">
      <!-- 테이블 div -->
      <div class="tip-table-wrapper">
        <table class="tip-table">
          <colgroup>
            <col style="width: 20%;" />
            <col style="width: 80%;" />
          </colgroup>
          <tbody>
            <tr>
              <th>주차장 주소</th>
              <td>{{ noticeData.address || '-' }}</td>
            </tr>
            <tr>
              <th>주차장 이름</th>
              <td>{{ noticeData.parkingName || '-' }}</td>
            </tr>
            <tr>
              <th>요금 정보</th>
              <td>
                {{ noticeData.firstMinute && noticeData.firstFee && noticeData.extraMinute && noticeData.extraFee
                  ? `${noticeData.firstMinute}분 기본 ${noticeData.firstFee}원, 이후 ${noticeData.extraMinute}분당
                ${noticeData.extraFee}원`
                  : '-' }}
              </td>
            </tr>
            <tr>
              <th>주차장연락처</th>
              <td>{{ noticeData.contact || '-' }}</td>
            </tr>
            <tr>
              <th>주차장사진</th>
              <td>
                <a :href="noticeData.imageDir" target="_blank" v-if="noticeData.imageDir">
                  이미지 보기
                </a>
                <span v-else>-</span>
              </td>
            </tr>

            <tr class="fill-space">
              <th>기타</th>
              <td>{{ noticeData.etc || '-' }}</td>
            </tr>
          </tbody>

        </table>
      </div>

      <!-- 답변 div -->
      <div class="tip-answer">

        <div class="textareaContainer">
          <div class="content-input">{{ noticeData.answer }}</div>
        </div>
      </div>
    </div>

    <!-- 등록 모달 -->
    <b-modal v-model="등록모달창열렸니" hide-footer hide-header centered>
      <div class="modalContents">
        <div class="modalImageWrapper">
          <img src="@/assets/icons/Vector.svg" alt="" class="modalCar" />
        </div>
        <div class="modalTextWrapper">
          <p class="mainAnswer">제보내역을 등록하시겠습니까?</p>
        </div>
        <div class="modalBtn">
          <b-button class="modal-blue-btn" @click="submitAnswer">네</b-button>
          <b-button class="modal-red-btn" @click="등록모달창열렸니 = false">아니오</b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "tipDetail00",
  props: ['id'],
  data() {
    return {
      모달창열렸니: false,
      등록모달창열렸니: false,
      noticeData: null,
      isEditMode: false, // 수정 모드 상태
      originalData: null, // 취소 시 복구용 원본 데이터
    };
  },
  created() {
    if (this.$route.query.itemData) {
      this.noticeData = JSON.parse(this.$route.query.itemData);
      this.originalData = JSON.parse(this.$route.query.itemData); // 원본 데이터 저장
    } else {
      this.$router.push("/parkinglotTip");
    }
  },
  methods: {
    goToLotTipPage() {
            this.$router.push("/parkinglotTip")
        },
    // 수정 모드 토글
    toggleEditMode() {
      if (this.isEditMode) {
        this.등록모달창열렸니 = true; // 저장 모달 열기
      } else {
        this.isEditMode = true;
      }
    },
    // 답변 저장 확정
    confirmSaveChanges() {
      this.isEditMode = false;
      this.등록모달창열렸니 = false;
      console.log("답변 저장 완료:", this.noticeData);
    },
    // 수정 취소
    confirmCancelEdit() {
      this.noticeData = JSON.parse(JSON.stringify(this.originalData)); // 원본 데이터로 복구
      this.isEditMode = false; // 수정 모드 해제
      console.log("수정 취소 완료");
    },

    // 답변 등록 API 호출
    async submitAnswer() {
      try {
        let status = '';

        // 상태 설정 (승인 or 거절)
        if (this.noticeData.isApproved) {
          status = 'COMPLETE';
        } else if (this.noticeData.isRejected) {
          status = 'REJECTED';
        }

        // Vuex 상태에서 선택된 버튼 가져오기
        const selectedButton = this.$store.state.selectedButton;

        // 선택된 버튼이 없으면 오류 처리
        if (!selectedButton) {
          throw new Error('선택된 버튼이 없습니다.');
        }

        // API 요청 보내기
        await this.$store.dispatch('submitAnswer', {
          selectedButton, // 선택된 버튼 전달
          answer: this.noticeData.answer, // 입력된 답변 전달
          status, // 상태 전달
          id: this.noticeData.id // 제보 ID 전달
        });

        alert('답변이 성공적으로 등록되었습니다.');
      } catch (error) {
        console.error('오류 발생:', error.message);
      }
    }
  }
};
</script>

<style>
.tip-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.25rem;
  width: 100%;
  margin-left: 6.25rem;
  padding-right: 150px;
  margin-top: 1.25rem;
  box-sizing: border-box;
  height: 70vh;
}

.tip-btn {
  display: flex;
  gap: 0.625rem;
  width: 100%;
  margin-left: 6.25rem;
  padding-right: 6.25rem;
  margin-top: 40px;
}

.tip-table-wrapper,
.tip-answer {
  border-radius: 1rem;
  width: 100%;
  overflow: hidden;
}

.tip-table {
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 1.125rem;
  overflow: hidden;
  min-height: 100%;
  background-color: white;
  border: 1px solid #6c757d;
  overflow-y: auto;
  /* 세로 스크롤 가능 */
  width: 100%;
  height: 100%;
}

.tip-table th,
.tip-table td {
  padding-right: 0.625rem;
  padding-left: 1.25rem;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  border: 1px solid #ddd;
  text-align: left;
  word-break: break-word;
  /* 긴 텍스트 줄바꿈 */
  white-space: normal;
  /* 텍스트가 두 줄 이상으로 나뉘도록 허용 */
}

.tip-table tbody tr.fill-space td {
  height: 100%;
  vertical-align: top;
}

.content-input {
  width: 100%;
  margin: 1rem;
  padding: 1rem;
  border: none;
  outline: none;
  box-shadow: none;
  border-radius: 4px;
  word-break: break-word;
  white-space: normal;
}

/* 수정 모드일 때 커서 숨기기 */
.no-cursor {
  pointer-events: none;
  caret-color: transparent;
}

.textareaContainer {
  display: flex;
  flex-direction: row;
  border: 1px #E0E0E0 solid;
  border-radius: 1rem;
  background-color: white;
  height: 100%;
}
</style>