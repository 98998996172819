<template>
  <div v-if="items && items.length > 0">
    <h4 class="listTitle">주차장 리스트</h4>
    <searchFilter :templateFileName="'주차장 등록 양식.xlsx'" :templateFilePath="'주차장 등록 양식_수정ver.xlsx'"
      :regionOptions="regionOptions" :cityOptions="cityOptions" :isParkingList="true"
      @filter-change="handleFilterChange" @delete-items="deleteSelectedItems" @file-uploaded="handleFileUpload"
      @refresh-list="fetchParkingList" :type="'type1'" />
    <basicTable :items="filteredItems" :columns="columns" :fields="fields" v-model="selectedItems"
      @update-selected-items="setSelectedData" :keyField="'id'" />
  </div>
</template>

<script>

import { mapState, mapActions } from 'vuex';
import searchFilter from "../components/filter.vue";
import basicTable from "../components/table.vue";
import { regionOptions, cityOptions } from '../assets/data.js';

export default {
  name: 'parkingList',
  components: {
    searchFilter,
    basicTable
  },
  data() {
    return {
      regionOptions: regionOptions,
      cityOptions: cityOptions,
      columns: [
        { title: "지역", width: "8%" },
        { title: "시군구", width: "8%" },
        { title: "주차장 이름", width: "15%" },
        { title: "주차장 주소", width: "22%" },
        { title: "기본시간(분)", width: "9%" },
        { title: "기본요금", width: "9%" },
        { title: "추가단위(분)", width: "9%" },
        { title: "추가요금", width: "9%" },
        { title: "기기설치 유무", width: "10%" }
      ],
      fields: ["region", "city", "name", "address","firstMinute", "firstFee", "extraMinute", "extraFee", "isInstalledDevice"],
      selectedRegion: null,
      selectedCity: null,
      activeButton: null,
      selectKeyword: null,
      search: '',
      selectedItems: [],
      tableData: [], // 테이블에 표시할 데이터
    };
  },
  computed: {
    ...mapState({
      items: state => {
        const parkingList = state.parkingList || [];  //
        return parkingList.map(item => {
          if (!item.address) {
            return { ...item, region: '', city: '', type: 'type1' };
          }
          const [region, city] = item.address.split(' ');
          return {
            ...item,
            region,
            city,
            type: 'type1'
          };
        });
      }
    }),
    filteredItems() {
      if (!this.items || this.items.length === 0) return [];  
      const selectedRegionText = this.regionOptions.find(option => option.value === this.selectedRegion)?.text;
      const selectedCityText = this.cityOptions[this.selectedRegion]?.find(option => option.value === this.selectedCity)?.text;

      return this.items.filter(item => {
        const regionMatch = !this.selectedRegion || item.region === selectedRegionText;
        const cityMatch = !this.selectedCity || item.city === selectedCityText;
        const deviceMatch = !this.activeButton || (this.activeButton === 'button1' ? item.isInstalledDevice : !item.isInstalledDevice);
        const keywordMatch = !this.selectKeyword || (item[this.selectKeyword]?.includes(this.search));

        return regionMatch && cityMatch && deviceMatch && keywordMatch;
      });
    }
  },
  methods: {
    ...mapActions(['getParkingList', 'deleteParkinglist', 'addParkingItems']),
    async fetchParkingList() {
      try {
        await this.getParkingList();
      } catch (error) {
        console.error('Error fetching parking lots:', error);
      }
    },
    handleRowClick(item, index) {
      // 행 클릭하면 데이터가 clickData에 담김   
      this.clickedData = item;
      this.selectedRowIndex = index;

      // 타입에 따라 다른 종류의 패널 띄우기
      this.activePanel =
        item.type === 'type1' ? 'clickedInfo' :
          item.type === 'type2' ? 'clickedInfo2' :
            item.type === 'type3' ? 'clickedInfo3' :
              'clickedInfo4';
      // 행 클릭시 background-color
      this.isDetailsVisible = true;
      this.selectedParking = item.name
    },
    handleFilterChange(filter) {
      this.selectedRegion = filter.selectedRegion;
      this.selectedCity = filter.selectedCity;
      this.activeButton = filter.activeButton;
      this.selectKeyword = filter.selectKeyword;
      this.search = filter.search;
    },
    deleteSelectedItems() {
      // 선택된 항목이 없는 경우 경고
      if (this.selectedItems.length === 0) {
        alert("삭제할 항목을 선택하세요.");
        return;
      }
      // 선택된 항목의 ID만 추출
      const selectedIds = this.selectedItems.map(item => item.id);
      this.$store.dispatch('deleteCheckParkinglist', selectedIds)
        .then(() => {
          this.selectedItems = []; // 선택 항목 초기화
          this.fetchParkingList(); // 목록 갱신
        })
        .catch(error => {
          console.error('Error deleting items:', error);
        });
    },
    setSelectedData(filteredList) {
      console.log("setSelectedData 함수 호출됨");  // 이 로그가 출력되는지 확인
      this.selectedItems = filteredList;
      console.log("체크박스 데이터:", filteredList);
    },
    // 자식 컴포넌트에서 전달된 엑셀 데이터를 테이블에 반영
    handleFileUpload(uploadedItems) {
      this.$store.dispatch('addParkingItems', uploadedItems);
    }
  },
  mounted() {
    this.fetchParkingList();
  },

  created() {
    this.fetchParkingList();
  }
}
</script>

<style>
.listTitle {
  margin-top: 50px;
  margin-left: 100px;
  font-size: 30px;
  font-weight: bolder;
}
</style>
