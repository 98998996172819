import createPersistedState from 'vuex-persistedstate';
import axios from '@/axios'; // 중앙에서 관리되는 axios 인스턴스 사용
import { createStore } from 'vuex';
// import { filter } from 'vue-cli-plugin-bootstrap-vue/sassAbstractsImports';


const store = createStore({
  state() {
    return {
      token: null,
      refreshToken: null,
      accessToken: null,
      user: null, // 사용자 정보 상태
      userProfile: [],
      parkingList: [],
      tempParking: [],
      noticeBoard: {},
      QandAlist: [],
      tipoffList: [],
      reserveList: [],
      ownerList: [],
      salesData: [],
      dashBoard: {
        totalUserCountInfo: {},
        totalParkingCountInfo: {},
        totalSalesCountInfo: {}
      },
      deviceList: [],
      error: null,
      selectedButton: null,
    parkingLayout: {
    savedCells: []  
  },
  cells:[],

    };
  },
  mutations: {
    setRefreshToken(state, refreshToken) {
      state.refreshToken = refreshToken;
    },
    setToken(state, token) {
      state.token = token;
    },
    setUser(state, user) {
      state.user = user;
    },
    logout(state) {
      state.token = null;
      state.refreshToken = null;
      state.user = null;
    },
    setuserProfile(state, userProfile) {
      state.userProfile = userProfile;
    },
    setParkingList(state, parkingList) {
      state.parkingList = parkingList;
    },
    addParkingItems(state, newItems) {
      state.parkingList = [...state.parkingList, ...newItems];
    },
    addOwnerItems(state, newItems) {
      state.ownerList = [...state.ownerList, ...newItems];
    },
    setError(state, error) {
      state.error = error;
    },
    setTempParking(state, tempParking) {
      state.tempParking = tempParking;
    },
    setNoticeBoard(state, noticeBoard) {
      state.noticeBoard = noticeBoard;
    },
    setQandA(state, QandAlist) {
      state.QandAlist = QandAlist;
    },
    setTipoffList(state, tipoffList) {
      state.tipoffList = tipoffList;
    },
    setReserveList(state, reserveList) {
      state.reserveList = reserveList;
    },
    setOwnerList(state, ownerList) {
      state.ownerList = ownerList;
    },
    setDashBoard(state, dashBoard) {
      state.dashBoard = dashBoard;
    },
    setSelectedButton(state, button) {
      state.selectedButton = button; // 버튼 선택 시 상태 업데이트
    },
    setDeviceList(state, deviceList) {
      state.deviceList = deviceList; // 상태에 기기 리스트 저장
    },
    setSalesData(state, salesData) {
      state.salesData = salesData; // 상태에 기기 리스트 저장
    },
    updateQandAItem(state, updatedItem) {
      const index = state.QandAlist.findIndex(item => item.id === updatedItem.id);
      if (index !== -1) {
        state.QandAlist.splice(index, 1, updatedItem);  // 해당 아이템을 교체
      }
    },
    // 엑셀파일 업로드시 테이블에 띄우기
    ADD_PARKING_ITEMS(state, newItems) {
      state.parkingList = [...state.parkingList, ...newItems];
    },

    // 한시적 주차 데이터 추가 (type2)
    ADD_TEMP_PARKING_ITEMS(state, newItems) {
      state.tempParking = [...state.tempParking, ...newItems];
    },

    ADD_OWNER_ITEMS(state, newItems) {
      state.ownerList = [...state.ownerList, ...newItems];
    },
    DELETE_PARKING_ITEMS(state, selectedIds) {
      // selectedIds에 있는 항목들을 필터링하여 제거
      state.parkingList = state.parkingList.filter(item => !selectedIds.includes(item.id));
    },
    UPDATE_PARKING_ITEM(state, updatedData) {
      const index = state.parkingList.findIndex(item => item.id === updatedData.id);
      if (index !== -1) {
        // 기존 항목을 업데이트된 데이터로 교체
        state.parkingList.splice(index, 1, updatedData);
      }
    },
    setParkingLayout(state, { horizontalCount, verticalCount, cells }) {
      state.horizontalCount = horizontalCount;
      state.verticalCount = verticalCount;
      state.savedCells = cells; // savedCells에 변환된 데이터를 저장
    },
    updateAnswerStatus(state, { id, status }) {
      // ID를 사용하여 상태를 업데이트하는 로직
      const answerIndex = state.answerStatusList.findIndex(item => item.id === id);
      if (answerIndex !== -1) {
        state.answerStatusList[answerIndex].status = status;
      } else {
        state.answerStatusList.push({ id, status });
      }
    },
  },
  actions: {

    // 페이지가 새로고침되었을 때 세션 스토리지에서 토큰을 가져오는 액션
    loadTokenFromSession({ commit }) {
      const token = sessionStorage.getItem('token');
      const refreshToken = sessionStorage.getItem('refreshToken');

      if (token) {
        commit('setToken', token);
      }
      if (refreshToken) {
        commit('setRefreshToken', refreshToken);
      }
    },
    /*******************************************************/
    /******************로그인&로그아웃***********************/
    /*******************************************************/
    async login({ commit }, userData) {
      try {
        const response = await axios.post('v1/auth', userData);
        const { accessToken, refreshToken } = response.data;



        // 토큰 설정
        commit('setToken', accessToken);
        commit('setRefreshToken', refreshToken);


        // 세션에 토큰 저장
        sessionStorage.setItem('token', accessToken);
        sessionStorage.setItem('refreshToken', refreshToken);

      } catch (error) {
        console.error('Login failed:', error);
        throw error;
      }
    },
    async logout({ commit }) {
      try {
        await axios.delete('v1/auth');
        sessionStorage.removeItem('token'); // 로컬 스토리지에서 토큰 제거
        sessionStorage.removeItem('token');
        commit('logout');  // Vuex 상태 초기화
      } catch (error) {
        console.error('Logout failed:', error);
      }
    },
    // user의 아이디 가져오기
    async userProfile({ commit, state }) {
      try {
        const response = await axios.get('v1/users/profile', {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },

        });
        console.log(response.data);
        commit('setuserProfile', response.data);
      } catch (error) {
        console.error('오류:', error.response ? error.response.data : error.message);
      }
    },

    /*******************************************************/
    /******************주차장 리스트*************************/
    /*******************************************************/

    // 주차장 리스트 불러오기
    async getParkingList({ commit, state }) {
      try {
        const response = await axios.get('v1/parking/admin', {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
          params: {
            "regionFilter": "ALL",
            "cityFilter": null
          }
        });
        console.log(response.data);
        commit('setParkingList', response.data.list);
      } catch (error) {
        console.error('오류:', error.response ? error.response.data : error.message);
      }
    },

    // 상세 패널 내 삭제
    async deleteParkinglist({ state, dispatch }, id) {
      console.log("삭제하려는 주차장:", id);
      try {
        await axios.delete(`v1/parking/${id}`, {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },

        });

        await dispatch('getParkingList');

      } catch (error) {
        console.error('오류:', error.response ? error.response.data : error.message);
      }
    },

    // 필터 내 삭제
    async deleteCheckParkinglist({ state, dispatch }, parkingId) {
      console.log("삭제하려는 주차장:", parkingId);
      try {
        await axios.delete(`v1/parking/selected`, {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
          data: {
            idList: parkingId,
          },
        });

        await dispatch('getParkingList');

      } catch (error) {
        console.error('오류:', error.response ? error.response.data : error.message);
      }
    },

    // 주차장 수정
    async updateParkinglist({ state, dispatch }, parkingData) {
      try {
        // 수정할 주차장 정보를 parkingData에 포함시켜서 보냄

        // parkingData의 내용을 명확히 출력
        console.log('서버로 보내는 데이터:', JSON.stringify(parkingData, null, 2));

        console.log('여기보세요:', parkingData.id);

        const response = await axios.put(`v1/parking/${parkingData.id}`, parkingData, {
          headers: {
            Authorization: `Bearer ${state.token}`, // 토큰 인증
          },
        });

        // 서버에서 반환된 updatedData를 response.data로 가정
        const updatedData = response.data;

        // 업데이트 후 주차장 리스트를 다시 가져옴
        await dispatch('getParkingList');

        // updatedData 반환
        return updatedData;
      } catch (error) {
        console.error('오류:', error.response ? error.response.data : error.message);
        throw error; // 오류 발생 시 호출자에게 에러를 던짐

      }
    },

    // 주차장 등록(엑셀)
    addParkingItems({ commit, state, dispatch }, formData) {
      // Vuex 상태에 추가
      commit('ADD_PARKING_ITEMS', formData);

      // FormData에 파일 추가

      const form = new FormData();
      form.append('registerParkingInfo', formData.get('file')); // formData에 이미 파일이 있는 경우

      try {
        // axios 요청에서 Content-Type은 자동으로 설정되므로 명시적으로 넣지 않음
        axios.post('v1/parking', form, {
          headers: {
            Authorization: `Bearer ${state.token}`, // 토큰만 설정
            // 'Content-Type'을 설정하지 않음
          },
        }).then(() => {
          console.log('Items successfully saved to the server.');
          dispatch('getParkingList'); // 서버에 저장된 후 주차장 리스트 다시 가져오기
        });
      } catch (error) {
        console.error('Error saving items to the server:', error);
      }
    },

     /*******************************************************/
    /******************기기도면 페이지************************/
   /*******************************************************/  

    // 기기 도면 설정
    async settingLayout({ commit, state }, { id, layoutData }) {
      try {
        const response = await axios.post(`v1/parking/${id}/seats`, layoutData, {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        });
        console.log('서버 응답 데이터:', response.data);
        commit('setParkingList', response.data.list); // 필요에 따라 Vuex 상태에 저장
      } catch (error) {
        console.error('오류:', error.response ? error.response.data : error.message);
      }
    },
    
    // 도면 불러오기
    async fetchLayoutByParkingId({ commit, state }, id) {
      try {
        const response = await axios.get(`v1/parking/${id}/seats`, {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        });
    
        // 서버에서 받은 데이터를 확인하여 필요한 형태로 변환
        const { list } = response.data;
    
        // `list`는 2차원 배열이므로 flatMap을 사용하여 1차원 배열로 변환
        const flatList = list.flatMap(row => row);
    
        // 각 셀 데이터를 저장할 형태로 변환
        const cells = flatList.map(cell => {
          // 시간 데이터를 "HH:mm:ss"에서 "HH:mm"으로 변환
          const formatTime = (time) => {
            if (time && time.includes(':')) {
              const [hour, minute] = time.split(':');
              return `${hour}:${minute}`;
            }
            return time; // 시간 형식이 없거나 잘못된 경우 그대로 반환
          };
          return {
            x: cell.x !== undefined ? cell.x : null,
            y: cell.y !== undefined ? cell.y : null,
            type: cell.type || '',  // 타입이 없으면 빈 문자열
            batch: cell.batch || null,  // 방향
            macAddress: cell.macAddress || '',  // 기기의 MAC 주소
            deviceId: cell.deviceId || '',  // 기기 ID
            availableStartTime: formatTime(cell.availableStartTime) || '00:00',  // 시작 시간 형식 변환
            availableEndTime: formatTime(cell.availableEndTime) || '00:00',  // 종료 시간 형식 변환
            status: cell.status || null  // 기기 상태
          };
        });
    
        // Vuex 상태에 반영
        commit('setParkingLayout', { cells });
    
        return { cells };
      } catch (error) {
        console.error('오류:', error.response ? error.response.data : error.message);
        throw error;
      }
    }
    ,
    // 도면 삭제 및 재설정
    async deleteLayout({ state, dispatch }, id) {
      try {
        const response = await axios.delete(`v1/parking/${id}/seats`, {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        });

        console.log('삭제된 도면 데이터:', response.data);
        await dispatch('fetchLayoutByParkingId', id);  

        return response.data;  
      } catch (error) {
        console.error('오류:', error.response ? error.response.data : error.message);
        throw error;
      }
    }
    ,

    /*******************************************************/
    /******************한시적 주차 허용**********************/
    /*******************************************************/


    // 한시적 주차 불러오기
    async getTempParking({ commit, state }) {
      try {
        const response = await axios.get('v1/temp-parking-permits', {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
          params: {
            "regionFilter": "ALL",
            "cityFilter": null
          }
        });
        console.log(response.data);
        commit('setTempParking', response.data.list);
      } catch (error) {
        console.error('오류:', error.response ? error.response.data : error.message);
      }
    },

    // 한시적 게시물 패널 내 삭제 
    async deleteTempParking({ state, dispatch }, id) {
      try {
        await axios.delete(`v1/temp-parking-permits/${id}`, {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },

        });

        await dispatch('getTempParking');

      } catch (error) {
        console.error('오류:', error.response ? error.response.data : error.message);
      }
    },

    // 필터 내 삭제
    async deleteCheckTempParking({ state, dispatch }, tempParkingPermitId) {
      console.log("삭제하려는 주차장:", tempParkingPermitId);
      try {
        await axios.delete(`v1/temp-parking-permits/selected`, {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
          data: {
            idList: tempParkingPermitId,
          },
        });

        await dispatch('getTempParking');

      } catch (error) {
        console.error('오류:', error.response ? error.response.data : error.message);
      }
    },

    // 한시적 주차 등록(엑셀)
    addTempParkingItems({ commit, state, dispatch }, formData) {
      commit('ADD_TEMP_PARKING_ITEMS', formData);


      const form = new FormData();
      form.append('registerTempParkingPermitInfo', formData.get('file'));
      try {

        axios.post('v1/temp-parking-permits', form, {
          headers: {
            Authorization: `Bearer ${state.token}`, // 토큰만 설정

          },
        }).then(() => {
          console.log('Items successfully saved to the server.');
          dispatch('getTempParking');
        });
      } catch (error) {
        console.error('Error saving items to the server:', error);
      }
    },


    /*******************************************************/
    /******************공지사항******************************/
    /*******************************************************/

    // 공지사항 불러오기
    async getNoticeBoard({ commit, state }) {
      try {
        const response = await axios.get('v1/notifications', {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        });
        console.log(response.data);

        commit('setNoticeBoard', response.data.list);
      } catch (error) {
        console.error('오류:', error.response ? error.response.data : error.message);
      }
    },

    // 공지사항 쓰기
    async createNotice({ state, dispatch }, noticeData) {
      try {
        const response = await axios.post(`v1/notifications`, noticeData, {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        });

        console.log("전체 응답:", response);  // 전체 응답 출력
        console.log("ssssssssssss", response.data);  // 응답 데이터 출력

        await dispatch('getNoticeBoard');  // 공지사항 업데이트
      } catch (error) {
        console.error('요청 실패:', error.response ? error.response.data : error.message);
      }
    },

    // 공지사항 삭제(수정 페이지 내)
    async deleteNotice({ state, dispatch }, id) {
      try {
        await axios.delete(`v1/notifications/${id}`, {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        });
        await dispatch('getNoticeBoard');
      } catch (error) {
        console.error('오류:', error.response ? error.response.data : error.message);
      }
    },

    // 필터 내 삭제
    async deleteCheckNotice({ state, dispatch }, notificationId) {
      console.log("삭제하려는 주차장 ID:", notificationId);
      try {
        await axios.delete(`v1/notifications/selected`, {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
          data: {
            idList: notificationId,
          },
        });

        await dispatch('getNoticeBoard');  // 게시판 목록을 다시 가져옴

      } catch (error) {
        console.error('오류:', error.response ? error.response.data : error.message);
      }
    },

    // 공지사항 수정
    async updateNotice({ state, dispatch }, { id, updateData }) {
      try {
        await axios.put(`v1/notifications/${id}`, updateData, {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        });

        // 공지사항 수정 후 목록을 다시 가져와 업데이트
        await dispatch('getNoticeBoard');
      } catch (error) {
        console.error('오류:', error.response ? error.response.data : error.message);
      }
    }

    ,

    /*******************************************************/
    /******************1:1 문의내역**************************/
    /*******************************************************/

    // 1:1 문의사항 전체 조회
    async getQandA({ commit, state }) {
      try {
        const response = await axios.get('v1/inquiries/admin', {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        });
        console.log(response.data);
        commit('setQandA', response.data.list);
      } catch (error) {
        console.error('오류:', error.response ? error.response.data : error.message);
      }
    },

    // 1:1 문의사항 답변등록
    async createAnswer({ state, dispatch }, { id, answer }) {
      try {
        await axios.post(`v1/inquiries/${id}/answer`, { answer }, {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        });
        await dispatch('getQandA');
      } catch (error) {
        console.error('오류:', error.response ? error.response.data : error.message);
      }
    }
    ,
    // // 1:1 문의사항삭제
    // async deleteQandA({ state, dispatch }, id) {
    //   try {
    //     const response = await axios.delete(`v1/inquiries/${id}`, {
    //       headers: {
    //         Authorization: `Bearer ${state.token}`,
    //       },
    //     });

    //     if (response.status === 200) {
    //       await dispatch('getQandA'); // 삭제 후 목록 갱신

    //     }
    //   } catch (error) {
    //     if (error.response) {
    //       if (error.response.data.code === 50002) {
    //         console.error('서버 오류:', error.response.data);
    //       }
    //     }
    //   }
    // },


    // 필터 내 삭제(미답변일때만)
    async deleteCheckQandA({ state, dispatch }, inquiryId) {
      console.log("삭제하려는 주차장:", inquiryId);
      try {
        await axios.delete(`v1/inquiries/selected`, {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
          data: {
            idList: inquiryId,
          },
        });

        await dispatch('getQandA');

      } catch (error) {
        console.error('오류:', error.response ? error.response.data : error.message);
      }
    },

    // 1:1 문의사항 수정
    async editQandA({ state, commit }, { id, answer }) {
      try {
        const response = await axios.patch(`v1/inquiries/${id}/answer`, { answer }, {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        });

        if (response.status === 200) {
          const updatedItem = response.data;
          commit('updateQandAItem', updatedItem);
        }
      } catch (error) {
        console.error('수정 중 오류 발생:', error);
      }
    }
    ,

    /*******************************************************/
    /******************제보내역******************************/
    /*******************************************************/


    // 주차장 제보 가져오기
    async getTipoffList({ commit, state }, apiEndpoint) {
      try {
        const response = await axios.get(apiEndpoint, {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        });
        console.log(response.data);
        commit('setTipoffList', response.data.list);
      } catch (error) {
        console.error('오류:', error.response ? error.response.data : error.message);
      }
    },


    // 주차장제보 답변
    async submitAnswer({ commit, state }, { id, status, answer }) {
      try {
        console.log("답변", answer)
        let apiEndpoint = state.selectedButton.apiEndpoint;

        console.log("여기", apiEndpoint)
        console.log("status", status)
        // 상태에 따른 추가 경로 설정(답변승인, 등록거절)
        if (status === 'COMPLETE') {
          apiEndpoint += `/${id}/resolve`;  // 템플릿 리터럴 사용
        } else if (status === 'REJECTED') {
          apiEndpoint += `/${id}/reject`;
        } else {
          throw new Error('잘못된 상태값입니다.');
        }
        const response = await axios.post(apiEndpoint, { answer: answer }, {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        });
        console.log('API 응답:', response.data);
        commit('updateAnswerStatus', { id, status });

      } catch (error) {
        console.error('오류 발생:', error.response ? error.response.data : error.message);
      }
    },

    // 주차장 제보 답변 삭제

    async deleteTipoffList({ state, dispatch }, { id, apiEndpoint }) {
      try {
        const deleteEndpoint = `${apiEndpoint}/${id}`;

        await axios.delete(deleteEndpoint, {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        });

        await dispatch('getTipoffList', apiEndpoint);
      } catch (error) {
        console.error('오류:', error.response ? error.response.data : error.message);
      }
    },

    /*******************************************************/
    /******************예약 리스트***************************/
    /*******************************************************/

    // 예약리스트 가져오기
    async getReserveList({ commit, state }, { parkingId = null, filterBy = null } = {}) {
      try {
        const response = await axios.get('v1/reservation/history', {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
          params: {
            parkingId,
            filterBy,
          },
        });
        console.log(response.data);
        commit('setReserveList', response.data.list);
      } catch (error) {
        console.error('오류:', error.response ? error.response.data : error.message);
      }
    },

    /*******************************************************/
    /******************주차장 소유자 관리********************/
    /*******************************************************/

    // 주차장 소유자 관리 리스트 가져오기
    async getOwnerList({ commit }) {
      try {
        const response = await axios.get('v1/parking-owner');
        console.log("소유자리스트", response.data);
        commit('setOwnerList', response.data.list);
      } catch (error) {
        console.error('오류:', error.response ? error.response.data : error.message);
      }
    },
    // 주차장 소유권 제거(모달창내)

    async deleteOwnership({ state, dispatch }, clickedData) {

      console.log("ownerId:", clickedData.ownerId, "parkingId:", clickedData.id);

      try {
        // 삭제 요청
        await axios.delete(`v1/parking-owner`, {
          headers: {
            Authorization: `Bearer ${state.token}`, // 토큰 인증 헤더
          },
          params: {
            ownerId: clickedData.ownerId,
            parkingId: clickedData.id
          }
        });

        // 성공 후 소유자 목록 갱신
        await dispatch('getOwnerList');

      } catch (error) {
        // 오류가 발생하면 콘솔에 출력
        console.error('오류:', error.response ? error.response.data : error.message);
      }
    },

    // 필터 내 삭제
    async deleteCheckOwnerList({ state, dispatch }, filteredList) {
      try {
        // ownerId 추출
        const idList = filteredList.map(item => item.ownerId);

        console.log("idList:", idList);

        // 각 ownerId에 대해 삭제 요청
        for (const userId of idList) {
          await axios.delete(`v1/parking-owner/${userId}`, {
            headers: {
              Authorization: `Bearer ${state.token}`,
            },
            data: {
              idList: idList,
            },
          });
        }
        // 삭제 후 리스트 갱신
        await dispatch('getOwnerList');


      } catch (error) {
        console.error('오류:', error.response ? error.response.data : error.message);
      }
    },



    // 소유자  엑셀 데이터 추가
    addOwnerItems({ commit, state, dispatch }, formData) {
      commit('ADD_OWNER_ITEMS', formData);


      const form = new FormData();
      form.append('registerParkingOwnerInfo', formData.get('file'));
      try {

        axios.post('v1/parking-owner', form, {
          headers: {
            Authorization: `Bearer ${state.token}`, // 토큰만 설정

          },
        }).then(() => {
          console.log('Items successfully saved to the server.');
          dispatch('getOwnerList');
        });
      } catch (error) {
        console.error('Error saving items to the server:', error);
      }
    },


    /*******************************************************/
    /************************대시보드************************/
    /*******************************************************/
    async getDashBoard({ commit, state }) {
      try {
        const response = await axios.get('v1/admin/dashboard', {
          headers: {
            Authorization: `Bearer ${state.token}`,
          }
        });
        console.log(response.data);
        commit('setDashBoard', response.data);
      } catch (error) {
        console.error('오류:', error.response ? error.response.data : error.message);
      }
    },
    /*******************************************************/
    /***********************기기 리스트**********************/
    /*******************************************************/

    // 기기 리스트 조회
    async getDeviceList({ commit, state }) {
      try {
        const response = await axios.get(`v1/parking/device`, {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        });
        console.log('기기 리스트', response.data.list)
        // 응답 데이터를 상태에 저장
        commit('setDeviceList', response.data.list);
      } catch (error) {
        console.error('오류:', error.response ? error.response.data : error.message);
      }
    },

    //  모달 창 내 삭제 
    async deleteDevicelist({ state, dispatch }, deviceId) {
      console.log("삭제하려는 주차장:", this.deviceId);
      try {
        await axios.delete(`v1/parking/device/${deviceId}`, {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },

        });

        await dispatch('getDeviceList');

      } catch (error) {
        console.error('오류:', error.response ? error.response.data : error.message);
      }
    },

    // 필터 내 삭제
    async deleteCheckDevice({ state, dispatch }, deviceUID) {
      console.log("삭제하려는 주차장:", deviceUID);
      try {
        await axios.delete(`v1/parking/device/selected`, {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
          data: {
            deviceUIDList: deviceUID,
          },
        });

        await dispatch('getParkingList');

      } catch (error) {
        console.error('오류:', error.response ? error.response.data : error.message);
      }
    },
    
    
    /** 매출통계 */

    async getSalesData({ commit, state }, { parkingId, startDate, endDate }) {
      try {
        const response = await axios.get('v1/parking/sales', {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
          params: {
            parkingId: parkingId,
            startDate: startDate,
            endDate: endDate,
          },
        });
        console.log('API 응답 데이터:', response.data);
        // response.data를 전체 저장하도록 변경
        commit('setSalesData', response.data);
      } catch (error) {
        console.error('오류:', error.response ? error.response.data : error.message);
      }
    },


  },

  getters: {
    isAuthenticated(state) {
      return !!state.token;
    },
    getUser(state) {
      return state.user;
    },
    getUserProfile(state) {
      return state.userProfile;
    },
    getParkingList(state) {
      return state.parkingList;
    },
    getError(state) {
      return state.error;
    },
    getTempParking(state) {
      return state.tempParking;
    },
    getNoticeBoard(state) {
      return state.noticeBoard;
    },
    getQandA(state) {
      return state.QandAlist;
    },
    getTipoffList(state) {
      return state.tipoffList;
    },
    getReserveList(state) {
      return state.reserveList;
    },
    getOwnerList(state) {
      return state.ownerList;
    },
    getDashBoard(state) {
      return state.dashBoard;
    },
    getDeviceList(state) {
      return state.deviceList;
    },
    getSalesData(state) {
      return state.salesData;
    }
  },

  plugins: [
    createPersistedState({
      paths: ['parkingList'],
      storage: window.sessionStorage,
    }),
  ],
});

export default store;