<template>
  <div class="largeLogin">
    <div class="mainLogo mb-2">
      <img class="mainLogoimg" src="@/assets/icons/carImg.svg" alt="Logo" />
    </div>
    <form method="post" id="login-form" @submit.prevent="loginSubmit">
      <div class="idForm">
        <p>아이디</p>
        <input type="text" v-model="userId" placeholder="아이디를 입력해주세요" @input="UserId" />
      </div>
      <div class="passwordForm mb-2">
        <p>비밀번호</p>
        <input type="password" v-model="password" placeholder="비밀번호를 입력해주세요" />
      </div>
      <input type="submit" value="로그인하기" />
      <p class="errorMessage" v-if="errorMessage" style="margin-top:1rem">{{ errorMessage }}</p> <!-- 에러 메시지 표시 -->
    </form>
  </div>
</template>

<script>
import { mapActions } from 'vuex';


export default {
  data() {
    return {
      userId: '',
      password: '',
      errorMessage: '',
    };
  },
  methods: {
    ...mapActions(['login']),

    UserId() {
      // 문자와 숫자만 허용
      this.userId = this.userId.replace(/[^a-zA-Z0-9]/g, '');
    },

    async loginSubmit() {
      this.errorMessage = '';
      if (!this.userId) {
        this.errorMessage = '아이디를 입력해주세요.';
        return;
      }
      if (!this.password) {
        this.errorMessage = '비밀번호를 입력해주세요.';
        return;
      }
      try {
        await this.login({
          userId: this.userId,
          password: this.password,
        });
        // 로그인 성공 시 대시보드로 이동
        this.$router.push('/dashboard');
      } catch (error) {
        if (error.response && error.response.status) {
          const statusCode = error.response.status;
          switch (statusCode) {
            case 400:
              this.errorMessage = '아이디와 비밀번호를 확인해주세요';
              break;
            case 404:
              this.errorMessage = '해당 사용자를 찾을 수 없습니다';
              break;
            case 500:
              this.errorMessage = '일시적인 오류로 로그인을 할 수 없습니다. 잠시 후 다시 이용해 주세요';
              break;
            default:
              this.errorMessage = '알 수 없는 오류가 발생했습니다. 다시 시도해주세요.';
          }
        } else {
          // 서버에 응답이 없거나 기타 네트워크 문제일 경우
          this.errorMessage = '네트워크 오류가 발생했습니다. ';
        }
      }
    },
  }
};
</script>

<style scoped>
.largeLogin {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 400px;
  padding: 1.25rem;
}

.mainLogo {
  display: flex;
  justify-content: center;
}

.mainLogoimg {
  width: 50%;
}

#login-form {
  width: 100%;
}

input[type="text"],
input[type="password"] {
  width: 100%;
  padding: 0.7rem;
  margin-top: 1px;
  margin-bottom: 2rem;
  border: none;
  border-radius: 0.7rem;
}

input[type="submit"] {
  width: 100%;
  padding: 0.7rem;
  background-color: #5b67ec;
  color: #fff;
  border: none;
  border-radius: 0.7rem;
  cursor: pointer;
}

input[type="submit"]:hover {
  background-color: #4a55d1;
}

p {
  font-weight: 500;
  font-size: 1.125rem;
}

p::before {
  content: "";
  width: 1rem;
  height: 1rem;
  background: #5b67ec;
  border: 3px solid #5b67ec;
  margin-right: 8px;
  border-radius: 0.625rem;
}

p.errorMessage::before {
  content: none;
  /* 에러 메시지일 때 ::before 요소를 숨김 */
}

.errorMessage {
  color: #F93C65;
}
</style>
