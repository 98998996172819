<template>
  <div>
    <h4 class="listTitle">공지사항</h4>

    <div class="notice-wrapper">
      <div class="btn-wrapper ">
        <router-link to="/NoticeWrite">
          <button class="blue-btn">글쓰기</button>
        </router-link>
        <button class="red-btn" @click="confirmDelete">삭제</button>
      </div>

      <table class="noticeTable table-hover" align="center">
        <thead>
          <tr>
            <th style="width: 5%;">
              <!-- 페이지 항목이 모두 선택된 경우에만 체크 -->
              <input type="checkbox" :checked="isPageAllSelected" @click="selectAll($event)">
            </th>
            <th style="width: 10%;">순번</th>
            <th style="width: 30%;">제목</th>
            <th style="width: 10%;">작성자</th>
            <th style="width: 10%;">상단고정</th>
            <th style="width: 20%;">작성날짜</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item,index) in paginatedItems" :key="item.id" @click="goToDetailPage(item)">
            <td><input type="checkbox" :value="item.id" v-model="selectedItems" @click.stop></td>
            <td>{{ index + 1 }}</td>
            <td>{{ item.title }}</td>
            <td>{{ item.writerName }}</td>
            <td>{{ item.isFixed ? '고정' : '-' }}</td>
            <td> {{ item.updatedDate? formatDate(item.updatedDate) : formatDate(item.createdDate) }} </td>
            <!-- <td>{{formatDate(item.createdDate)  }}</td> -->
          </tr>
        </tbody>
      </table>

      <div>
        <b-pagination class="tablePage" :per-page="perPage" v-model="currentPage" :total-rows="rows" align="center"
          aria-controls="my-table"></b-pagination>
      </div>
    </div>

    <!-- 삭제 확인 모달 -->
    <b-modal v-model="모달창열렸니" hide-footer hide-header centered>
      <div class="modalContents">
        <div class="modalImageWrapper">
          <img src="@/assets/icons/Vector.svg" alt="" class="modalCar">
        </div>
        <div class="modalTextWrapper">
          <p class="mainAnswer">공지사항을 삭제하시겠습니까?</p>
          <p>삭제된 공지사항은 복구되지 않습니다</p>
        </div>
        <div class="modalBtn">
          <b-button class="modal-blue-btn" @click="deleteItem">네</b-button>
          <b-button class="modal-red-btn" @click="모달창열렸니 = false">아니오</b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'noticeBoard',
  data() {
    return {
      모달창열렸니: false,
      currentPage: 1,
      perPage: 20,
      selectedItems: [], // 체크박스로 선택된 항목들
      items: [], // 공지사항 리스트
    };
  },
  computed: {
    rows() {
      return Array.isArray(this.items) ? this.items.length : 0;
    },
    paginatedItems() {
      if (!Array.isArray(this.items) || this.items.length === 0) {
        return [];
      }
      const fixedItems = this.items.filter(item => item.isFixed);
      const normalItems = this.items.filter(item => !item.isFixed);
      const sortedItems = [...fixedItems, ...normalItems];

      const start = (this.currentPage - 1) * this.perPage;
      const end = start + this.perPage;
      return sortedItems.slice(start, end);
    },
    isPageAllSelected() {
      // 현재 페이지에 있는 항목들이 모두 선택된 경우만 true 반환
      return this.paginatedItems.length > 0 && this.paginatedItems.every(item => this.selectedItems.includes(item.id));
    }
  },
  methods: {
    // 현재 페이지의 항목에 대해서만 선택/해제 기능
    selectAll(event) {
      const isChecked = event.target.checked;
      const pageItemIds = this.paginatedItems.map(item => item.id);

      if (isChecked) {
        // 현재 페이지의 항목들만 선택 추가
        this.selectedItems = [...new Set([...this.selectedItems, ...pageItemIds])];
      } else {
        // 현재 페이지의 항목들만 선택 해제
        this.selectedItems = this.selectedItems.filter(id => !pageItemIds.includes(id));
      }
      console.log('Selected Items:', this.selectedItems);
    },

    // 공지사항 상세 페이지로 이동
    goToDetailPage(item) {
      this.$router.push({
        name: 'noticeDetail',
        params: { id: item.id },
        query: { itemData: JSON.stringify(item) },
      });
    },

    // 삭제 모달 창 확인
    confirmDelete() {
      if (this.selectedItems.length === 0) {
        alert('삭제할 항목을 선택하세요.');
        return;
      }
      this.모달창열렸니 = true;
    },

    // 선택된 항목 삭제
    async deleteItem() {
      if (this.selectedItems.length > 0) {
        try {
          // 선택된 항목 ID 리스트를 deleteCheckNotice에 한 번에 전달
          await this.$store.dispatch('deleteCheckNotice', this.selectedItems);

          this.모달창열렸니 = false;  // 모달 닫기
          this.selectedItems = [];  // 선택 항목 초기화
          await this.fetchNoticeBoard();  // 목록 갱신
        } catch (error) {
          console.error('삭제 중 오류 발생:', error);
        }
      } else {
        alert('삭제할 항목이 선택되지 않았습니다.');
      }
    },

    ...mapActions(['getNoticeBoard', 'deleteNotice']),

    async fetchNoticeBoard() {
      try {
        await this.getNoticeBoard();
        this.items = this.$store.state.noticeBoard;
      } catch (error) {
        console.error('Error fetching notice board data:', error);
      }
    },
    formatDate(dateString) {
    if (!dateString) return '-';
    const date = new Date(dateString);
    
    const year = date.getFullYear().toString().slice(-2); // 연도의 마지막 두 자리 추출
    const month = String(date.getMonth() + 1).padStart(2, '0'); // 월 (0부터 시작하므로 +1) 두 자리로 표시
    const day = String(date.getDate()).padStart(2, '0'); // 일 두 자리로 표시

    const hours = String(date.getHours()).padStart(2, '0'); // 시간 두 자리로 표시
    const minutes = String(date.getMinutes()).padStart(2, '0'); // 분 두 자리로 표시

    return `${year}.${month}.${day}\u00A0\u00A0\u00A0${hours}:${minutes}`;
  },
  },
  created() {
    this.fetchNoticeBoard(); // 컴포넌트가 생성될 때 데이터를 자동으로 불러옴
  },
};
</script>

<style>
.listTitle {
  margin-top: 50px;
  margin-left: 6.25rem;
  font-size: 1.9rem;
  font-weight: bolder;
}

.notice-wrapper {

  display: flex;
  margin-bottom: 1.25rem;
}

.btn-wrapper {
  margin-bottom: 1.25rem;
  display: flex;
  gap: 0.625rem;
}

.noticeTable {
  margin-bottom: 1.25rem;
  flex: 2;
  border-collapse: separate;
  border-spacing: 0;
  border: 1px solid #6c757d !important;
  background-color: #ffffff;
  border-radius: 8px;
  width: 100%;
}
.noticeTable th,
.noticeTable td{
padding-left: 1rem !important;
}
.noticeTable thead th:first-child {
  border-top-left-radius: 1rem;
}

.noticeTable thead th:last-child {
  border-top-right-radius: 1rem;
}

.noticeTable thead {
  color: black;
  font-weight: 100;
}

.noticeTable thead th {
  border-bottom: 2px solid #eaeaea;
}

.noticeTable tbody tr td {
  border-bottom: 1px solid #eaeaea;
}

.noticeTable tbody tr.fixed {
  font-weight: bold;
  background-color: #f7f7f7;
  /* 상단 고정된 항목에 대한 배경색 지정 */
}
</style>
