import '@babel/polyfill'
import 'mutationobserver-shim'

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/store'
import axios from './axios';

import '@/global.css'

// BootstrapVue3 추가
import BootstrapVue3 from 'bootstrap-vue-3'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'

//fontawesome
import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all.js';

// 애플리케이션에 BootstrapVue3 사용
const app = createApp(App);
store.dispatch('loadTokenFromSession'); 

app.use(router);
app.use(store); 
app.use(BootstrapVue3);

app.config.globalProperties.$axios = axios;


app.mount('#app');
