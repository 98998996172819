<template>
  <div class="navbar">
    <div class="navContent"  >
      <div>
        <img src="../assets/icons/userImg.svg" alt="">
      </div>
      <div v-if="getUserProfile">
        {{ getUserProfile.userId }}
      </div>
      <button @click="toggleDropdown" class="dropdown-button"></button>
      <div v-if="isDropdownOpen" class="dropdown-content">
        <a href="#" @click.prevent="handleLogout">로그아웃</a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  data() {
    return {
      isDropdownOpen: false,
    };
  },
  computed: {
    ...mapGetters(['getUserProfile']) // userProfile getter 연결
  },
  created() {
    this.userProfile();
    console.log('User Profile:', this.getUserProfile);
  },
  methods: {
    // 드롭다운 열기/닫기 토글
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    // 외부 클릭 감지로 드롭다운 닫기
    handleClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.isDropdownOpen = false;
      }
    },
    // 로그아웃 처리
    async handleLogout() {
      try {
        await this.logout();  // Vuex 스토어의 logout 액션 호출
        this.$router.push('/loginPage');  // 로그아웃 후 로그인 페이지로 리디렉션
      } catch (error) {
        console.error('Logout failed:', error);
      }
    },
    ...mapActions(['logout', 'userProfile']),
  },
  mounted() {
    window.addEventListener('click', this.handleClickOutside);
  },
  beforeUnmount() {
    window.removeEventListener('click', this.handleClickOutside);
  }
};
</script>

<style scoped>
.navbar {
  display: flex;
  gap: 1rem;
  background-color: white;
  padding: 0.5rem 1rem;
  justify-content: flex-end;
  position: relative;
}

.dropdown-button {
  width: 1.5rem;
  height: 1.5rem;
  background-image: url('/src/assets/icons/More.svg');
  background-color: white;
  background-size: 1.125rem 1.125rem;
  background-repeat: no-repeat;
  background-position: right center;
  border: none;
  cursor: pointer;
  padding: 0;
}

.dropdown-content {
  display: block;
  position: absolute;
  top: 120%;
  right: 0;
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
  min-width: 10rem;
  z-index: 1000;
  opacity: 1;
  transform: translateY(0);
  pointer-events: auto;
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.dropdown-content a {
  color: #333;
  padding: 0.75rem 1rem;
  text-decoration: none;
  display: block;
  font-weight: 500;
  font-size: 1rem;
  border-bottom: 1px solid #f1f1f1;
  transition: background-color 0.3s ease;
}

.dropdown-content a:last-child {
  border-bottom: none;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}
.navContent{
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  justify-content:space-around;  
  position: relative;
  padding: 0.5rem;
}

</style>
