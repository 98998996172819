<template>
  <div>
    <h4 class="listTitle">한시적 주차 허용 리스트</h4>

    <!-- searchFilter 컴포넌트 -->
    <searchFilter :templateFileName="'한시적 주차 허용 등록 양식.xlsx'" :templateFilePath="'/한시적 주차 허용 등록 양식.xlsx'"
      :regionOptions="regionOptions" :cityOptions="cityOptions" :isParkingList="false"
      @filter-change="handleFilterChange" @delete-items="deleteSelectedItems" :route="$route"
      @refresh-list="fetchTempParking" :type="'type2'" />

    <!-- 기본 테이블 -->
    <basicTable :items="filteredItems" :columns="columns" :fields="fields" @update-selected-items="setSelectedData"
      :keyField="'id'" />

  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import searchFilter from "../components/filter.vue";
import basicTable from "../components/table.vue";
import { regionOptions, cityOptions } from '../assets/data.js';

export default {
  name: 'temporaryParking',
  data() {
    return {
      regionOptions: regionOptions,
      cityOptions: cityOptions,
      columns: [
        { title: "지역", width: "10%" },
        { title: "시군구", width: "10%" },
        { title: "주차장 구간", width: "25%" },
        { title: "허용 시작 시간", width: "15%" },
        { title: "허용 종료 시간", width: "15%" },
        { title: "설명", width: "15%" },
        { title: "구간", width: "10%" },
      ],
      fields: ["region", "city", "name", "startTime", "endTime", "description", "distance"],
      selectedRegion: null,
      selectedCity: null,
      selectKeyword: null,
      search: '',
      selectedItems: [], // 선택된 항목의 ID 저장
      모달창열렸니: false, // 모달 열림 상태 관리
    }
  },
  computed: {
    ...mapState({
      items: state => {
        return state.tempParking.map(item => {
          if (!item.address) {
            return { ...item, region: '', city: '', type: 'type2' };
          }
          const [region, city] = item.address.split(' ');
          return {
            ...item,
            region,
            city,
            type: 'type2'
          };
        });
      }
    }),
    filteredItems() {
      const selectedRegionText = this.regionOptions.find(option => option.value === this.selectedRegion)?.text;
      const selectedCityText = this.cityOptions[this.selectedRegion]?.find(option => option.value === this.selectedCity)?.text;

      return this.items
        .filter(item => {
          const regionMatch = !this.selectedRegion || item.region === selectedRegionText;
          const cityMatch = !this.selectedCity || item.city === selectedCityText;
          const keywordMatch = !this.selectKeyword || (item[this.selectKeyword]?.includes(this.search));
          return regionMatch && cityMatch && keywordMatch;
        })
        .map(item => {
          // 시간 포맷팅: 초 단위 제거
          const formatTime = time => time ? time.slice(0, 5) : ''; // "HH:MM" 형식으로 자름

          return {
            ...item,
            startTime: formatTime(item.startTime),
            endTime: formatTime(item.endTime),

          };
        });
    }
  },
  methods: {
    ...mapActions(['getTempParking', 'deleteTempParking']),

    async fetchTempParking() {
      try {
        await this.getTempParking(); // Vuex 액션 호출
      } catch (error) {
        console.error('Error fetching parking lots:', error);
      }
    },
    logSelectedItems() {
      console.log('logSelectedItems function called');
      console.log('Selected items:', JSON.parse(JSON.stringify(this.selectedItems)));
    },
    // 선택된 항목 삭제 처리
    deleteSelectedItems() {
      // 선택된 항목이 없는 경우 경고
      if (this.selectedItems.length === 0) {
        alert("삭제할 항목을 선택하세요.");
        return;
      }
      // 선택된 항목의 ID만 추출
      const selectedIds = this.selectedItems.map(item => item.id);
      this.$store.dispatch('deleteCheckTempParking', selectedIds)
        .then(() => {
          this.selectedItems = []; // 선택 항목 초기화
          this.fetchTempParking(); // 목록 갱신
        })
        .catch(error => {
          console.error('Error deleting items:', error);
        });
    },
    handleFilterChange({ selectedRegion, selectedCity, selectKeyword, search }) {
      this.selectedRegion = selectedRegion;
      this.selectedCity = selectedCity;
      this.selectKeyword = selectKeyword;
      this.search = search;
    },

    setSelectedData(filteredList) {
      console.log("setSelectedData 함수 호출됨");  // 이 로그가 출력되는지 확인
      this.selectedItems = filteredList;
      console.log("체크박스 데이터:", filteredList);
    },
  },
  mounted() {
    this.$emit('refresh-list', this.fetchTempParking);
  },
  created() {
    this.fetchTempParking();
  },

  components: {
    searchFilter,
    basicTable,
  },
};
</script>



<style>
.listTitle {
  margin-top: 50px;
  margin-left: 6.25rem;
  font-size: 1.9rem;
  font-weight: bolder;
}
</style>