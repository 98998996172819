<template>
  <div>
    <h4 class="listTitle">대시보드</h4>
    <div class="dashWrapper">
      <div class="dashMap" id="map" style="width: 100%; "></div>

      <div class="cardWrapper"> <!-- 전체 카드-->

        <!-- 전체 누적 사용자 수 카드 -->
        <div class="dashCard">
          <div class="topCard">
            <div>
              <div class="dashTitle">
                <h6>전체 누적 사용자 수</h6>
              </div>
              <div class="dashNum">
                <div class="NumDiv">
                  <h4 style="font-weight: 900;">
                    {{totalUserCountInfo.totalCount}}
                    <span style="font-family: NanumSquareNeoExtraBold; font-size: 1.25rem;">명</span>
                  </h4>
                </div>
              </div>
            </div>
            <div>
              <img :src="require('@/assets/icons/totalUser.svg')" alt="">
            </div>
          </div>

          <div class="dashAnalysis">
            <template v-if="totalUserCountInfo.comparePreviousDay === 0">
              <span>- 변동 없음</span> <!-- 0일 때는 "-"만 표시 -->
            </template>
            <template v-else>
              <img :src="getTrendIcon(totalUserCountInfo.comparePreviousDay)" alt="상태 아이콘">
              <h6>
                <span :class="totalUserCountInfo.comparePreviousDay > 0 ? 'positive' : 'negative'">
                  {{ Math.abs(totalUserCountInfo.comparePreviousDay).toFixed(1) }}%
                </span>
                전일 대비 {{ totalUserCountInfo.comparePreviousDay > 0 ? '상승' : '하락' }}
              </h6>
            </template>
          </div>
        </div>

        <!-- 전체 주차장 수 카드 -->
        <div class="dashCard">
          <div class="topCard">
            <div>
              <div class="dashTitle">
                <h6>전체 주차장 수</h6>
              </div>
              <div class="dashNum">
                <div class="NumDiv">
                  <h4 style="font-weight: 900;">
                    {{ totalParkingCountInfo.totalCount }}
                    <span style="font-family: NanumSquareNeoExtraBold; font-size: 1.25rem;">곳</span>
                  </h4>
                </div>
              </div>
            </div>
            <div>
              <img :src="require('@/assets/icons/totalPaking.svg')" alt="">
            </div>
          </div>

          <div class="dashAnalysis">
            <template v-if="totalParkingCountInfo.comparePreviousDay === 0">
              <span>- 변동 없음</span> <!-- 0일 때는 "-"만 표시 -->
            </template>
            <template v-else>
              <img :src="getTrendIcon(totalParkingCountInfo.comparePreviousDay)" alt="상태 아이콘">
              <h6>
                <span :class="totalParkingCountInfo.comparePreviousDay > 0 ? 'positive' : 'negative'">
                  {{ Math.abs(totalParkingCountInfo.comparePreviousDay).toFixed(1) }}%
                </span>
                전일 대비 {{ totalParkingCountInfo.comparePreviousDay > 0 ? '상승' : '하락' }}
              </h6>
            </template>
          </div>
        </div>

        <!-- 전체 매출 카드 -->
        <div class="dashCard">
          <div class="topCard">
            <div>
              <div class="dashTitle">
                <h6>전체 매출</h6>
              </div>
              <div class="dashNum">
                <div class="NumDiv">
                  <h4 style="font-weight: 900;">
                    {{ totalSalesCountInfo.totalCount }}
                    <span style="font-family: NanumSquareNeoExtraBold; font-size: 1.25rem;">원</span>
                  </h4>
                </div>
              </div>
            </div>
            <div>
              <img :src="require('@/assets/icons/totalSales.svg')" alt="">
            </div>
          </div>

          <div class="dashAnalysis">
            <template v-if="totalSalesCountInfo.comparePreviousMonth === 0">
              <span>- 변동 없음</span> <!-- 0일 때는 "-"만 표시 -->
            </template>
            <template v-else>
              <img :src="getTrendIcon(totalSalesCountInfo.comparePreviousMonth)" alt="상태 아이콘">
              <h6>
                <span :class="totalSalesCountInfo.comparePreviousMonth > 0 ? 'positive' : 'negative'">
                  {{ Math.abs(totalSalesCountInfo.comparePreviousMonth).toFixed(1) }}%
                </span>
                전월 대비 {{ totalSalesCountInfo.comparePreviousMonth > 0 ? '상승' : '하락' }}
              </h6>
            </template>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
/* global naver */
import { mapState, mapActions } from 'vuex';

export default {
  name: 'DashBoard',
  computed: {
    ...mapState({
      totalUserCountInfo: state => state.dashBoard.totalUserCountInfo,
      totalParkingCountInfo: state => state.dashBoard.totalParkingCountInfo,
      totalSalesCountInfo: state => state.dashBoard.totalSalesCountInfo,
    })
  },
  methods: {
    ...mapActions(['getDashBoard']),
    async fetchDashBoard() {
      try {
        await this.getDashBoard(); // Vuex 액션 호출
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
      }
    },
    getTrendIcon(value) {
      return value >= 0
        ? require('@/assets/icons/trending-up.svg')
        : require('@/assets/icons/trending-down.svg');
    },
    loadNaverMap() {
      const script = document.createElement('script');
      script.src = 'https://oapi.map.naver.com/openapi/v3/maps.js?ncpClientId=c9nk30tsu8';
      script.async = true;
      script.onload = () => {
        var mapOptions = {
          center: new naver.maps.LatLng(35.1595454, 126.8526012),
          zoom: 14
        };
        new naver.maps.Map('map', mapOptions);
      };
      document.head.appendChild(script);
    },
    formatTotalCount(value) {
      const absValue = Math.abs(value);
      return absValue % 1 === 0 ? absValue : absValue.toFixed(1); 
    },

  },
  created() {
    this.fetchDashBoard(); // 컴포넌트가 생성될 때 데이터를 자동으로 불러옴
  },
  mounted() {
    this.loadNaverMap(); // 네이버 지도 로드
  }
}
</script>

<style>
.dashWrapper {
  margin-left: 6.25rem;
  margin-right: 6.25rem;
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  gap: 1.25rem;
}

.cardWrapper {
  display: flex;
  flex-direction: column;
}

.topCard {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.dashMap {
  flex: 4;
}

.dashCard {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 1.25rem;
  border-radius: 1.125rem;
  background-color: white;
  margin-bottom: 1.9rem;
  width: 260px;
  height: 161px;
}

.dashCard:last-child {
  margin-bottom: 0;
}

.dashTitle {
  font-weight: bold;
  color: #6c757D;
  margin-bottom: 1.25rem;
}

.dashNum {
  margin-bottom: 1.9rem;
}

.dashAnalysis {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0;
  color: #6c757D;
  gap: 0.625rem;
}

.dashAnalysis h6 {
  margin: 0;
}

.positive {
  color: #5b67ec;
}

.negative {
  color: #f93c65;
}
</style>
