<template>
  <div class="container">
    <div class="seat-content no-margin no-padding">
      <div class="grid-container" :style="{ gridTemplateColumns: `repeat(${maxX}, 1fr)`, gridTemplateRows: `repeat(${maxY}, 1fr)` }">
        <div
          v-for="(lot, index) in parkingLots.flat()" 
          :key="index"
          class="grid-item"
          :style="{
            gridRow: lot.x + 1, 
            gridColumn: lot.y + 1 
          }" 
          :class="{ 'selected': isSelectedLot(lot) }">
          <img 
          v-if="lotIcon(lot)" 
          :src="lotIcon(lot)" 
          class="item-src"
          :alt="`${lot.type} Icon`" />
          <img
            v-if="isSelectedLot(lot)"
            :src="selectedIcon"
            class="selected-overlay"
          />
        </div>
      </div>
    </div>
    </div>
</template>

<script>
import noIcon from '../assets/Group 143726377.svg'; // 예약 불가 상태 아이콘
import eastIcon from '../assets/ic_patch_east.svg'; // EAST 상태 아이콘
import westIcon from '../assets/ic_patch_west.svg'; // WEST 상태 아이콘
import southIcon from '../assets/ic_patch_south.svg'; // SOUTH 상태 아이콘
import northIcon from '../assets/ic_patch_north.svg'; // NORTH 상태 아이콘
import selectedIcon from '../assets/ic_selected.svg';

const TYPE_DEVICE = 'DEVICE';
const TYPE_SEAT = 'SEAT';
const TYPE_ROAD = 'ROAD';
const BATCH_EAST = 'EAST';
const BATCH_WEST = 'WEST';
const BATCH_SOUTH = 'SOUTH';
const BATCH_NORTH = 'NORTH';

export default {
  data() {
    return {
      parkingLots: [
        [ 
          {
              "macAddress": null,
              "deviceId": null,
              "x": 0,
              "y": 0,
              "type": "SEAT",
              "batch": null,
              "status": null,
              "availableStartTime": null,
              "availableEndTime": null
          },
          {
              "macAddress": null,
              "deviceId": null,
              "x": 1,
              "y": 0,
              "type": "ROAD",
              "batch": null,
              "status": null,
              "availableStartTime": null,
              "availableEndTime": null
          },
          {
              "macAddress": null,
              "deviceId": null,
              "x": 2,
              "y": 0,
              "type": "SEAT",
              "batch": null,
              "status": null,
              "availableStartTime": null,
              "availableEndTime": null
          }
        ],
        [
          {
              "macAddress": null,
              "deviceId": null,
              "x": 0,
              "y": 1,
              "type": "SEAT",
              "batch": null,
              "status": null,
              "availableStartTime": null,
              "availableEndTime": null
          },
          {
              "macAddress": null,
              "deviceId": null,
              "x": 1,
              "y": 1,
              "type": "ROAD",
              "batch": null,
              "status": null,
              "availableStartTime": null,
              "availableEndTime": null
          },
          {
              "macAddress": null,
              "deviceId": null,
              "x": 2,
              "y": 1,
              "type": "SEAT",
              "batch": null,
              "status": null,
              "availableStartTime": null,
              "availableEndTime": null
          },
        ],
        [
          {
              "macAddress": null,
              "deviceId": null,
              "x": 0,
              "y": 2,
              "type": "SEAT",
              "batch": null,
              "status": null,
              "availableStartTime": null,
              "availableEndTime": null
          },
          {
              "macAddress": null,
              "deviceId": null,
              "x": 1,
              "y": 2,
              "type": "ROAD",
              "batch": null,
              "status": null,
              "availableStartTime": null,
              "availableEndTime": null
          },
          {
              "macAddress": null,
              "deviceId": null,
              "x": 2,
              "y": 2,
              "type": "DEVICE",
              "batch": "NORTH",
              "status": "WAITING",
              "availableStartTime": "00:00:00",
              "availableEndTime": "20:00:00"
          },
        ]
      ],
      maxX: 0,
      maxY: 0,
      selectedLot: {"x": 2, "y": 2,},
      selectedIcon: selectedIcon,
    };
  },
  computed: {
    id() {
      return this.$route.query.id;
    },
    authToken() {
      return this.$route.query.authToken;
    },
  },
  methods: {
    getIconByTypeAndBatch(type, status, batch) {
      if (type === TYPE_DEVICE && status === 'WAITING') {
        switch (batch) {
          case BATCH_EAST: return eastIcon;
          case BATCH_WEST: return westIcon;
          case BATCH_SOUTH: return southIcon;
          case BATCH_NORTH: return northIcon;
          default: return null;
        }
      } else if (type === TYPE_SEAT) {
        return noIcon;
      } else if (type === TYPE_ROAD) {
        return null;
      }
      return noIcon;
    },
    handleSelect(lot) {
      if (lot.type === TYPE_DEVICE && lot.status === 'WAITING') {
        this.selectedLot = lot;
      }
    },
    isSelectedLot(lot) {
      return this.selectedLot && this.selectedLot.x === lot.x && this.selectedLot.y === lot.y;
    },
    lotIcon(lot) {
      if (lot.type === 'DEVICE' && lot.status === 'WAITING') {
        switch (lot.batch) {
          case 'EAST':
            return eastIcon;
          case 'WEST':
            return westIcon;
          case 'SOUTH':
            return southIcon;
          case 'NORTH':
            return northIcon;
          default:
            return null;
        }
      } else if (lot.type === 'SEAT') {
        return noIcon;  // SEAT일 때 예약 불가 아이콘
      } else if (lot.type === 'ROAD') {
        return null; // ROAD는 아이콘 없음
      }
      return null;
    },
  },
};
</script>

<style scoped>
/* 여기에 필요한 스타일 추가 */
.container {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
}

.grid-container {
  display: grid;
  width: 100%;
  height: 100%;
}

.grid-item {
  height: 30vw; /* 화면 너비의 % */
  width: 30vw; /* 화면 너비의 % */
  max-width: 80px; /* 최대 크기 설정 (선택 사항) */
  max-height: 80px; /* 최대 크기 설정 (선택 사항) */
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid transparent;
}

.item-src {
  height: 95%; /* 각 칸의 높이 */
  width: 95%; /* 각 칸의 너비 */
}

.lotDiv {
  display: inline-block;
}


.parkingLot {
  background-color: white;
  border: none;
  cursor: pointer;
  position: relative;
}

.selected-overlay {
  position: absolute;
  top: 0;  /* 상단에 위치 */
  left: 0;  /* 좌측에 위치 */
  height: 100%;  /* grid-item 크기에 맞게 조정 */
  width: 100%;  /* grid-item 크기에 맞게 조정 */
  z-index: 2;
}
</style>
