<template>
  <!-- 공지사항 새로 작성 -->
  <div>
    <h4 class="listTitle">공지사항</h4>

    <div class="notice-wrapper">
      <div class="btn-wrapper">
        <button class="blue-btn" @click="등록모달창열렸니 = true">등록</button>
        <button class="black-btn" @click="취소모달창열렸니 = true">취소</button>
      </div>

      <div>
        <input class="textarea-input" type="text" placeholder="제목" v-model="noticeData.title">
        <div class="textarea-fixed">
          <input type="checkbox" v-model="noticeData.isFixed" id="isFixed" />
          <label for="isFixed">상단고정</label>
        </div>
        <div class="textarea-container">
          <div class="label">
            본문내용
          </div>
          <textarea class="input-area" v-model="noticeData.content"></textarea>
        </div>
      </div>

      <!-- 등록 모달 -->
      <b-modal v-model="등록모달창열렸니" hide-footer hide-header centered>
        <div class="modalContents">
          <div class="modalImageWrapper">
            <img src="@/assets/icons/Vector.svg" alt="" class="modalCar">
          </div>
          <div class="modalTextWrapper">
            <p class="mainAnswer">공지사항을 등록하시겠습니까?</p>
          </div>
          <div class="modalBtn">
            <b-button class="modal-blue-btn" @click="confirmCreateNotice">네</b-button>
            <b-button class="modal-red-btn" @click="등록모달창열렸니 = false">아니오</b-button>
          </div>
        </div>
      </b-modal>

      <!-- 취소 모달 -->
      <b-modal v-model="취소모달창열렸니" hide-footer hide-header centered>
        <div class="modalContents">
          <div class="modalImageWrapper">
            <img src="@/assets/icons/Vector.svg" alt="" class="modalCar">
          </div>
          <div class="modalTextWrapper">
            <p class="mainAnswer">공지사항 등록을 취소하시겠습니까?</p>
            <p>변경된 사항은 저장되지 않습니다.</p>
          </div>
          <div class="modalBtn">
            <b-button class="modal-blue-btn" @click="toNoticeBoard">네</b-button>
            <b-button class="modal-red-btn" @click="취소모달창열렸니 = false">아니오</b-button>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import Swal from 'sweetalert2';

export default {
  data() {
    return {
      등록모달창열렸니: false,
      취소모달창열렸니: false,
      noticeData: {
        title: '',
        content: '',
        isFixed: false, // 상단고정 여부
      }
    };
  },
  methods: {
    ...mapActions(['createNotice']), // Vuex 액션 연결

    // 공지사항 등록 확인
    // 공지사항 등록 확인
    async confirmCreateNotice() {
      console.log("전송 데이터:", this.noticeData); // 전송 전 데이터 확인
      this.등록모달창열렸니 = false; // 모달창 닫기

      // 입력값 유효성 검사
      if (!this.noticeData.title.trim() || !this.noticeData.content.trim()) {
        Swal.fire({
          imageUrl: require('@/assets/icons/Vector.svg'),
          imageWidth: 55,
          imageHeight: 55,
          html: `<div style='font-size:1.5rem; margin-bottom: 1rem; font-weight:900;'>입력값을 확인하세요</div>
             <div style='font-size:1rem; margin-top: 1rem;'><span style='color:#F93C65;'>제목</span>과 <span style='color:#F93C65;'>본문내용</span>은 필수 입력 값입니다</div>`,
          confirmButtonText: '확인',
          confirmButtonColor: '#5B67EC',
        });
        return; // 빈 값일 경우 함수 종료
      }

      // 유효성 검사를 통과한 경우에만 서버로 데이터 전송
      try {
        await this.createNotice({
          title: this.noticeData.title,
          content: this.noticeData.content,
          isFixed: this.noticeData.isFixed,
        });
        console.log("데이터 전송 성공");
        this.$router.push('/noticeBoard');
      } catch (error) {
        console.error('공지사항 등록 중 오류 발생:', error);
      }
    },
    // 공지사항 작성 취소 시 공지사항 목록으로 이동
    toNoticeBoard() {
      this.$router.push('/noticeBoard'); // 공지사항 목록으로 이동
    }
  }
};
</script>

<style>
.listTitle {
  margin-top: 50px;
  margin-left: 6.25rem;
  font-size: 1.9rem;
  font-weight: bolder;
}

.notice-wrapper {
  display: flex;
  flex-direction: column;
  margin-left: 6.25rem;
  padding-right: 6.25rem;
  margin-top: 50px;
}

.btn-wrapper {
  margin-bottom: 1.25rem;
  display: flex;
  gap: 0.625rem;
}

.textarea-container {
  height: 50vh;
  display: flex;
  flex-direction: row;
  border: 1px #E0E0E0 solid;
  border-radius: 1rem;
  background-color: white;
}

.label {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.625rem;
  border-right: 1px solid #E0E0E0;
}

.input-area {
  flex: 9;
  outline: none;
  border: none;
  padding: 0.625rem;
  margin: 0.625rem;
}

.textarea-input {
  width: 100%;
  height: 5vh;
  border: 1px #E0E0E0 solid;
  border-radius: 1rem;
  outline: none;
  padding: 1.25rem;
}

.textarea-fixed {
  display: flex;
  gap: 0.625rem;
  margin-top: 0.625rem;
  margin-bottom: 1.25rem;
  margin-left: 0.625rem;
}

.equal-width-btn {
  flex: 1;
  display: inline-block;
  width: 6.25rem;
  text-align: center;
}
</style>
