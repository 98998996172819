<template>
  <div>
    <h4 class="listTitle">매출통계</h4>
    <div class="salesWrapper">
      <div class="buttonSection">
        <div class="fristbtn">
          <select v-model="parkingSelect" @change="fetchSalesData" class="salesSelect">
            <option :value="null">주차장 선택</option>
            <option v-for="item in parkingOptions" :key="item.id" :value="item.id">
              {{ item.name }}
            </option>
          </select>

          <button class="auto-width-button blue-btn" @click="downloadExcel">엑셀다운로드</button>
        </div>
        <div class="secondbtn">
          <button class="day-btn" v-for="button in buttons" :key="button.value"
            :class="{ 'selected-button': selectedButton === button.value }" @click="selectButton(button.value)">
            {{ button.label }}
          </button>
          <!-- 날짜 범위 선택 -->
          <vue3-datepicker locale="ko" :enable-time-picker="false" v-model="dateRange" range
            :start-placeholder="'시작 날짜'" :end-placeholder="'종료 날짜'" :range-separator="'~'" format="yyyy-MM-dd"
            :default-date="new Date()" />
        </div>
      </div>

      <div class="card-section" v-if="salesData">
        <div class="salesCard">
          <h5>총 매출액</h5>
          <h6>결제건 총액</h6>
          <h3 :style="{ color: '#00B69B', textAlign: 'right' }">{{ formatNumber(salesData.totalAmount) }} 원</h3>
        </div>

        <div class="salesCard">
          <h5>취소 금액</h5>
          <h6>취소건 총액</h6>
          <h3 :style="{ color: '#F93C65', textAlign: 'right' }">{{ formatNumber(salesData.cancelAmount) }} 원</h3>
        </div>

        <div class="salesCard">
          <h5>PG사 수수료</h5>
          <h6>결제승인건의 4.3%</h6>
          <h3 :style="{ color: '#F93C65', textAlign: 'right' }">{{ formatNumber(salesData.pgVat) }} 원</h3>
        </div>

        <div class="salesCard">
          <h5>주차장 소유자 수수료</h5>
          <h6>PG 수수료 제외한 매출액의 60%</h6>
          <h3 :style="{ color: '#F93C65', textAlign: 'right' }">{{ formatNumber(salesData.parkingOwnerVat) }} 원</h3>
        </div>

        <div class="salesCard">
          <h5>총 이익</h5>
          <h6>결제건-(취소+PG+소유자 수수료)</h6>
          <h3 :style="{ color: '#5B67EC', textAlign: 'right' }">{{ formatNumber(salesData.totalProfit) }} 원</h3>
        </div>
      </div>


      <table class="noticeTable table-hover" align="center">
        <thead>
          <tr>
            <th style="width: 5%;">순번</th>
            <th style="width: 25%;">주차장 이름</th>
            <th style="width: 20%;">거래 일시</th>
            <th style="width: 15%;">결제 금액(원)</th>
            <th style="width: 15%;">취소 금액(원)</th>
            <th style="width: 10%;">카드사명</th>
            <th style="width: 10%;">결제 상태</th>
          </tr>

        </thead>
        <tbody>
          <tr v-for="(item, index) in paginatedItems" :key="item.id">
            <td>{{ index + 1 }}</td>
            <td>{{ item.parkingName }}</td>
            <td>{{ formatDate(item.paymentDateTime) }}</td> <!-- 거래 일시 -->
            <td>{{ typeof item.paymentPrice === 'number' ? formatNumber(item.paymentPrice) : '-' }}</td> <!-- 취소 금액 -->
            <td>{{ typeof item.cancelPrice === 'number' ? formatNumber(item.cancelPrice) : '-' }}</td> <!-- 취소 금액 -->
            <td>{{ item.cardName }}</td> <!-- 카드사명 -->
            <td :style="{ color: colorMap[item.status] || 'black' }">{{ stateLabel(item.status) }}</td>
          </tr>
        </tbody>
      </table>

      <div>
        <b-pagination class="tablePage" :per-page="perPage" v-model="currentPage" :total-rows="filteredItems.length"
          align="center" aria-controls="my-table">
        </b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Vue3Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import * as XLSX from 'xlsx';

export default {
  name: 'salesData',
  components: {
    Vue3Datepicker,
  },
  data() {
    return {
      parkingSelect: null,
      selectedDate: null,
      currentPage: 1,
      perPage: 20,
      selectedButton: null,
      buttons: [
        { label: '오늘', value: 'today' },
        { label: '이번주', value: 'thisWeek' },
        { label: '이번달', value: 'thisMonth' },
      ],
      items: [],
      filteredItems: [],
      parkingOptions: [],
      dateRange: [],
      defaultDate: new Date(),
      colorMap: {
        PAYMENT_DONE: 'blue',
        CANCELED: 'red',
      },
      salesData: {},
    };
  },
  computed: {
    rows() {
      return this.items.length;
    },
    paginatedItems() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = start + this.perPage;
      return (this.filteredItems || []).slice(start, end);
    },
    ...mapState({
      parkingList: state => state.parkingList, // Vuex에서 parkingList 가져오기
    }),
  },
  watch: {
    parkingList(newList) {
      this.parkingOptions = newList.map(({ id, name }) => ({ id, name }));
    },
    dateRange(newRange) {
      if (newRange.length === 2) {
        this.fetchSalesData();
      }
    }
  },
  methods: {
    ...mapActions(['getParkingList', 'getSalesData']),

    async fetchSalesData() {
      try {
        const parkingId = this.parkingSelect || null;

        if (!this.selectedButton) {
          this.selectedButton = 'today';
          this.dateRange = [new Date(), new Date()]; // 오늘 날짜 설정
        }

        const [startDate, endDate] = this.dateRange.length === 2
          ? [this.dateRange[0], this.dateRange[1]]
          : [null, null];


        const formattedStartDate = startDate ? startDate.toISOString().split('T')[0] : new Date().toISOString().split('T')[0];
        const formattedEndDate = endDate ? endDate.toISOString().split('T')[0] : new Date().toISOString().split('T')[0];


        // API로부터 데이터를 가져옵니다.
        await this.getSalesData({
          parkingId: parkingId,
          startDate: formattedStartDate,
          endDate: formattedEndDate,
        });

        // Vuex 상태에서 데이터를 가져옵니다.
        const salesData = this.$store.state.salesData;

        if (salesData) {
          this.salesData = salesData;
        } else {
          console.error('salesData가 유효하지 않습니다:', salesData);
        }

        // salesData와 salesData.salesInfoList가 유효한지 확인합니다.
        if (salesData && Array.isArray(salesData.salesInfoList)) {
          this.items = salesData.salesInfoList.map(info => ({
            parkingId: info.parkingId,
            orderId: info.orderId,
            parkingName: info.parkingName,
            paymentDateTime: info.paymentDateTime,
            paymentPrice: info.paymentPrice,
            cancelPrice: info.cancelPrice ? info.cancelPrice : '-',
            cardName: info.cardName,
            status: info.status,
          }));
        } else {
          console.error('salesData 또는 salesInfoList가 유효하지 않습니다:', salesData);
          this.items = [];
        }

        this.filteredItems = this.items;
      } catch (error) {
        console.error('데이터를 가져오는 중 오류 발생:', error);
        this.items = [];
        this.filteredItems = [];
      }
    },



    async fetchParkingList() {
      try {
        await this.getParkingList();
      } catch (error) {
        console.error('Error fetching parking lots:', error);
      }
    },

    selectButton(value) {
      if (this.selectedButton === value) {
        this.selectedButton = null;
        this.dateRange = []; // 날짜 범위를 초기화
        this.fetchSalesData();
      } else {
        // 버튼을 처음 눌렀을 때
        this.selectedButton = value;

        const today = new Date();
        let startDate, endDate;

        if (value === 'today') {
          startDate = today;
          endDate = today;
        } else if (value === 'thisWeek') {
          const firstDayOfWeek = new Date(today.setDate(today.getDate() - today.getDay()));
          const lastDayOfWeek = new Date(today.setDate(today.getDate() - today.getDay() + 6));
          startDate = firstDayOfWeek;
          endDate = lastDayOfWeek;
        } else if (value === 'thisMonth') {
          startDate = new Date(today.getFullYear(), today.getMonth(), 1);
          endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        }

        // dateRange에 설정한 날짜를 반영
        this.dateRange = [startDate, endDate];

        // 설정된 날짜로 데이터를 가져옵니다.
        this.fetchSalesData();
      }
    },



    stateLabel(status) {
      const statusMap = {
        PAYMENT_DONE: '승인',
        CANCELED: '취소',
      };
      return statusMap[status] || '-';
    },

    async downloadExcel() {
      try {
        const allData = [];

        // 모든 주차장에 대해 매출 데이터를 가져옵니다.
        for (let option of this.parkingOptions) {
          await this.getSalesData({
            parkingId: option.id,
            startDate: this.dateRange[0] ? this.dateRange[0].toISOString().split('T')[0] : null,
            endDate: this.dateRange[1] ? this.dateRange[1].toISOString().split('T')[0] : null,
          });

          const salesData = this.$store.state.salesData;
          if (salesData && Array.isArray(salesData.salesInfoList)) {
            const items = salesData.salesInfoList.map(info => ({
              '순번': info.parkingId,
              '주차장 이름': info.parkingName,
              '거래 일시': info.paymentDateTime,
              '결제 금액(원)': info.paymentPrice || '-',
              '취소 금액(원)': info.cancelPrice || '-',
              '카드사명': info.cardName,
              '결제 상태': this.stateLabel(info.status),
            }));
            allData.push(...items);
          }
        }

        // 데이터가 없는 경우 빈 테이블을 만들어 줍니다.
        const dataToExport = allData.length > 0 ? allData : [{
          '순번': '',
          '주차장 이름': '',
          '거래 일시': '',
          '결제 금액(원)': '',
          '취소 금액(원)': '',
          '카드사명': '',
          '결제 상태': ''
        }];

        // 워크북과 시트 생성
        const worksheet = XLSX.utils.json_to_sheet(dataToExport);
        const workbook = XLSX.utils.book_new();

        // 시트를 워크북에 추가
        XLSX.utils.book_append_sheet(workbook, worksheet, "매출통계");

        // 파일을 엑셀 형식으로 다운로드
        XLSX.writeFile(workbook, "매출통계.xlsx");
      } catch (error) {
        console.error('엑셀 다운로드 중 오류 발생:', error);
      }
    },
    setDateRangeToLastMonth() {
      const today = new Date();
      const lastMonth = new Date();
      lastMonth.setMonth(today.getMonth() - 1);

      // dateRange에 직접 설정
      this.dateRange = [lastMonth, today];
      this.fetchSalesData(); // 날짜 범위 변경 후 매출 데이터 가져오기
    },
    formatDate(dateString) {
      if (!dateString) return '-';
      const date = new Date(dateString);

      const year = date.getFullYear().toString().slice(-2); // 연도의 마지막 두 자리 추출
      const month = String(date.getMonth() + 1).padStart(2, '0'); // 월 (0부터 시작하므로 +1) 두 자리로 표시
      const day = String(date.getDate()).padStart(2, '0'); // 일 두 자리로 표시

      const hours = String(date.getHours()).padStart(2, '0'); // 시간 두 자리로 표시
      const minutes = String(date.getMinutes()).padStart(2, '0'); // 분 두 자리로 표시

      return `${year}.${month}.${day}\u00A0\u00A0\u00A0${hours}:${minutes}`;
    },
    formatNumber(value) {
      if (!value) return '-';
      return new Intl.NumberFormat().format(value);
    },
  },

  created() {
    this.fetchParkingList();
    this.fetchSalesData();
  },
};
</script>



<style>
.listTitle {
  margin-top: 50px;
  margin-left: 6.25rem;
  font-size: 1.9rem;
  font-weight: bolder;
}

.card-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1.25rem;
  justify-content: space-between;
  gap: 1.25rem;
}

.salesCard {
  flex: 1;
  margin-bottom: 0.625rem;
  padding: 1.5rem;
  width: 50px;
  border: 2px solid #e2e6ea;
  background: #FFFFFF;
  border-radius: 18px;
}

.salesCard h5 {
  font-size: 18px;
  color: #6C757D;
  font-weight: 900;
}

.salesCard h6 {
  font-size: 14px;
  margin-bottom: 1rem;
}

.salesCard h3 {
  font-weight: 900;

}

.buttonSection {
  display: flex;
  flex-direction: row;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  justify-content: space-between;
}

.fristbtn {
  display: flex;
  flex-direction: row;
  gap: 0.625rem;
}

.fristbtn>button {
  background-color: white;

}

.secondbtn {
  display: flex;
  gap: 0.625rem;
}

.day-btn {
  width: auto;
  height: 40px;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  border: 1px solid #e2e6ea;
  border-radius: 8px;
  background-color: white;
  cursor: pointer;
}


.salesWrapper {
  margin-left: 6.25rem;
  padding-right: 6.25rem;
  margin-top: 50px;
}

.day-input {
  width: auto;
  height: 40px;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  border: 1px solid #e2e6ea;
  border-radius: 8px;
  background-color: #f8f9fa;
  cursor: pointer;
}

.selected-button {
  color: #5B67EC;
  border-color: #5B67EC;
}

.salesSelect {
  width: 11.25rem;
  height: 40px;
  padding: 0.3rem 0.625rem;
  font-size: 1rem;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.5rem;
}
</style>