<template>
  <div>
    <h4 class="listTitle">문의내역</h4>
    <div class="notice-wrapper">
      <div class="btn-wrapper ">
        <button class="blue-btn" :class="{ active: activeFilter === null }" @click="setFilterStatus(null)">
          전체보기
        </button>
        <button class="blue-btn" :class="{ active: activeFilter === 'WAITING' }" @click="setFilterStatus('WAITING')">
          미답변보기
        </button>
        <button class="red-btn" :disabled="selectedItems.length === 0" @click="모달창열렸니 = true">
          삭제
        </button>
      </div>

      <table class="noticeTable table-hover" align="center">
        <thead>
          <tr>
            <th style="width: 3%;"><input type="checkbox" :checked="isPageAllSelected" @click="selectAll($event)" />
            </th>
            <th style="width: 7%;">순번</th>
            <th style="width: 15%;">제목</th>
            <th style="width: 10%;">작성자</th>
            <th style="width: 15%;">작성 날짜</th>
            <th style="width: 10%;">답변 여부</th>
            <th style="width: 10%;">답변자</th>
            <th style="width: 15%;">답변 날짜</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in paginatedItems" :key="item.id" @click="goToDetailPage(item)"
            :class="{ selected: selectedRowIndex === index }">
            <td><input type="checkbox" :value="item.id" v-model="selectedItems" @click.stop /></td>
            <td>{{ index+1 }}</td>
            <td>{{ item.title }}</td>
            <td>{{ item.writerName }}</td>
            <td>{{ formatDate(item.createdDate) }}</td>
            <td :style="statusColor(item.status)">
              {{ statusResultLabel(item.status) }}
            </td>
            <td>{{ item.writerName }}</td>
            <td>{{ formatDate(item.updatedDate) }}</td>
          </tr>
        </tbody>
      </table>

      <div>
        <b-pagination class="tablePage" :per-page="perPage" v-model="currentPage" :total-rows="rows" align="center"
          aria-controls="my-table"></b-pagination>
      </div>
    </div>

    <!-- 삭제 모달 -->
    <b-modal v-model="모달창열렸니" hide-footer hide-header centered>
      <div class="modalContents">
        <div class="modalImageWrapper">
          <img src="@/assets/icons/Vector.svg" alt="" class="modalCar" />
        </div>
        <div class="modalTextWrapper">
          <p class="mainAnswer">문의내역을 삭제하시겠습니까?</p>
          <p>삭제된 문의내역은 복구되지 않습니다</p>
        </div>
        <div class="modalBtn">
          <b-button class="modal-blue-btn" @click="deleteSelectedItems">네</b-button>
          <b-button class="modal-red-btn" @click="모달창열렸니 = false">아니오</b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Swal from 'sweetalert2';

export default {
  name: "QandA",
  data() {
    return {
      모달창열렸니: false,
      currentPage: 1,
      perPage: 20,
      selectedRowIndex: null,
      items: [], // 데이터 배열
      filterStatus: null, // status가 waiting 이거나 null(전체)
      selectedItems: [], // 선택된 항목의 ID 배열
      activeFilter: null,
    };
  },
  computed: {
    rows() {
      return Array.isArray(this.items) ? this.items.length : 0;
    },
    paginatedItems() {
      if (!Array.isArray(this.items) || this.items.length === 0) {
        return [];
      }
      let filteredItems = this.items;
      if (this.filterStatus === "WAITING") {
        filteredItems = this.items.filter((item) => item.status === "WAITING");
      }
      const start = (this.currentPage - 1) * this.perPage;
      const end = start + this.perPage;
      return filteredItems.slice(start, end);
    },
    isPageAllSelected() {
      // 현재 페이지에 있는 항목들이 모두 선택된 경우만 true 반환
      return this.paginatedItems.length > 0 && this.paginatedItems.every(item => this.selectedItems.includes(item.id));
    }
  },
  methods: {
    statusResultLabel(status) {
      const statusMap = {
        WAITING: "답변 대기",
        COMPLETE: "답변 완료",
      };
      return statusMap[status] || "-";
    },
    statusColor(status) {
      const colorMap = {
        COMPLETE: "#5b67ec",
        WAITING: "#F93C65",
      };
      return { color: colorMap[status] || "black" };
    },
    goToDetailPage(item) {
      if (item.status === "WAITING") {
        this.$router.push({
          name: "QandAdetail",
          params: { id: item.id },
          query: { itemData: JSON.stringify(item) },
        });
      } else if (item.status === "COMPLETE") {
        this.$router.push({
          name: "QandAedit",
          params: { id: item.id },
          query: { itemData: JSON.stringify(item) },
        });
      } else {
        this.$router.push({
          name: "QandA",
          params: { id: item.id },
          query: { itemData: JSON.stringify(item) },
        });
      }
    },
    selectAll(event) {
      const isChecked = event.target.checked;
      const pageItemIds = this.paginatedItems.map(item => item.id);

      if (isChecked) {
        // 현재 페이지의 항목들만 선택 추가
        this.selectedItems = [...new Set([...this.selectedItems, ...pageItemIds])];
      } else {
        // 현재 페이지의 항목들만 선택 해제
        this.selectedItems = this.selectedItems.filter(id => !pageItemIds.includes(id));
      }
    },
    async deleteSelectedItems() {
      try {
        console.log('Deleting selected items', this.selectedItems);

        // 선택된 항목들이 있는지 확인 후 삭제 요청
        if (this.selectedItems.length > 0) {
          // 선택된 항목 중 답변 완료 상태가 있는지 확인
          const completedItems = this.items.filter(item =>
            this.selectedItems.includes(item.id) && item.status === 'COMPLETE'
          );
          this.모달창열렸니 = false;
          if (completedItems.length > 0) {
            // 상태가 COMPLETE인 항목이 있으면 알림 표시
            Swal.fire({
              imageUrl: require('@/assets/icons/Vector.svg'),
              html: `<div style='font-size:1.5rem; margin-bottom: 1rem; font-weight:900;'>삭제할 수 없습니다</div>
                 <div style='font-size:1rem; margin-top: 1rem;'> 답변여부가 <span style="color: #F93C65; font-weight:900;">답변완료</span>인 항목은 삭제할 수 없습니다</div>`,
              confirmButtonText: '확인',
              confirmButtonColor: '#5B67EC',
              imageWidth: 55,
              imageHeight: 55,
            });
            return; // 알림 후 함수 종료
          }

          // 답변 완료 상태가 없는 경우에만 삭제 진행
          await this.$store.dispatch('deleteCheckQandA', this.selectedItems);
          // 삭제 성공 알림 추가
          Swal.fire({
            imageUrl: require('@/assets/icons/Vector.svg'),
              html: `<div style='font-size:1.5rem; margin-bottom: 1rem; font-weight:900;'>삭제가 완료되었습니다</div>`,
              confirmButtonText: '확인',
              confirmButtonColor: '#5B67EC',
              imageWidth: 55,
              imageHeight: 55,
          });
        }
        this.selectedItems = [];  // 선택된 항목 초기화
        this.모달창열렸니 = false;  // 모달 창 닫기
        await this.fetchQandA();  // 최신 상태로 목록 업데이트
      } catch (error) {
        console.error('Error deleting items:', error);
        this.모달창열렸니 = false;
      }
    }
    ,


    setFilterStatus(status) {
      this.filterStatus = status;
      this.activeFilter = status;
      this.currentPage = 1;
    },
    ...mapActions(["getQandA", "deleteQandA"]),
    async fetchQandA() {
      try {
        await this.getQandA();
        this.items = this.$store.state.QandAlist;
      } catch (error) {
        console.error("Error fetching Q&A:", error);
      }
    },
    formatDate(dateString) {
      if (!dateString) return '-';
      const date = new Date(dateString);

      const year = date.getFullYear().toString().slice(-2); // 연도의 마지막 두 자리 추출
      const month = String(date.getMonth() + 1).padStart(2, '0'); // 월 (0부터 시작하므로 +1) 두 자리로 표시
      const day = String(date.getDate()).padStart(2, '0'); // 일 두 자리로 표시

      const hours = String(date.getHours()).padStart(2, '0'); // 시간 두 자리로 표시
      const minutes = String(date.getMinutes()).padStart(2, '0'); // 분 두 자리로 표시

      return `${year}.${month}.${day}\u00A0\u00A0\u00A0${hours}:${minutes}`;
    },

  },
  mounted() {
    this.fetchQandA();
  },
};
</script>

<style>
.listTitle {
  margin-top: 50px;
  margin-left: 6.25rem;
  font-size: 1.9rem;
  font-weight: bolder;
}

.notice-wrapper {
  display: flex;
  flex-direction: column;
  margin-left: 6.25rem;
  padding-right: 6.25rem;
  margin-top: 50px;
}

.btn-wrapper {
  margin-bottom: 1.25rem;
  display: flex;
  gap: 0.625rem;
}

.noticeTable {
  margin-bottom: 1.25rem;
}

.noticeTable th,
.noticeTable td {
  padding: 0.625rem;
}

.blue-btn.active {
  background-color: #5b67ec;
  color: white;
}

.red-btn.active {
  background-color: #F93C65;
  color: white;
}

.noticeTable th,
.noticeTable td {
  font-variant-numeric: tabular-nums;
  /* 숫자가 일정한 폭을 차지하도록 설정 */
}
</style>
