<template>
  <!-- 답변 완료 수정 -->

  <h4 class="listTitle">문의내역</h4>
  <div class="notice-wrapper ">
    <div class="btn-wrapper">
      <button class="black-btn" v-if="!isEditing" @click="navigateToQandA">←</button>
      <button v-if="!isEditing" class="blue-btn" @click="답변수정모달 = true">답변수정</button>

      <!-- isEditing이 true일 때만 답변저장과 취소 버튼 표시 -->
      <button v-if="isEditing" class="blue-btn" @click="답변저장모달 = true">답변저장</button>
      <button v-if="isEditing" class="black-btn" @click="취소">취소</button>
    </div>

    <!-- 답변 수정 모달 -->
    <b-modal v-model="답변수정모달" hide-footer hide-header centered>
      <div class="modalContents">
        <div class="modalImageWrapper">
          <img src="@/assets/icons/Vector.svg" alt="" class="modalCar">
        </div>
        <div class="modalTextWrapper">
          <p class="mainAnswer">답변을 수정하시겠습니까?</p>
        </div>
        <div class="modalBtn">
          <b-button class="modal-blue-btn" @click="startEditing">네</b-button>
          <b-button class="modal-red-btn" @click="답변수정모달 = false">아니오</b-button>
        </div>
      </div>
    </b-modal>

    <!-- 답변 저장 모달 -->
    <b-modal v-model="답변저장모달" hide-footer hide-header centered>
      <div class="modalContents">
        <div class="modalImageWrapper">
          <img src="@/assets/icons/Vector.svg" alt="" class="modalCar">
        </div>
        <div class="modalTextWrapper">
          <p class="mainAnswer">답변을 저장하시겠습니까?</p>
        </div>
        <div class="modalBtn">
          <b-button class="modal-blue-btn" @click="저장">네</b-button>
          <b-button class="modal-red-btn" @click="답변저장모달 = false">아니오</b-button>
        </div>
      </div>
    </b-modal>

    <!-- 답변 삭제 모달 -->
    <b-modal v-model="답변삭제모달" hide-footer hide-header centered>
      <div class="modalContents">
        <div class="modalImageWrapper">
          <img src="@/assets/icons/Vector.svg" alt="" class="modalCar">
        </div>
        <div class="modalTextWrapper">
          <p class="mainAnswer">답변을 삭제하시겠습니까?</p>
          <p>변경된 사항은 저장되지 않습니다</p>
        </div>
        <div class="modalBtn">
          <b-button class="modal-blue-btn" @click="삭제">네</b-button>
          <b-button class="modal-red-btn" @click="답변삭제모달 = false">아니오</b-button>
        </div>
      </div>
    </b-modal>

    <table class="qandaTable" align="center">
      <colgroup>
        <col style="width: 10%;" />
        <col style="width: 90%;" />
      </colgroup>
      <tr class="title-row">
        <td>제목</td>
        <td>{{ noticeData.title }}</td>
      </tr>
      <tr>
        <td>작성날짜</td>
        <td>{{  formatDate(noticeData.updatedDate) }}</td>
      </tr>
      <tr>
        <td>작성자</td>
        <td>{{ noticeData.writerName }}</td>
      </tr>
      <tr class="question-row">
        <td class="large-cell">문의내역</td>
        <td class="answer-textarea">{{ noticeData.content }}</td>
      </tr>
      <tr class="answer-row">
        <td class="large-cell">답변</td>
        <td>
          <textarea v-model="answer" :disabled="!isEditing" :class="{'inactive': !isEditing}" class="answer-textarea"></textarea>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
export default {
  data() {
    return {
      noticeData: null,
      답변수정모달: false,
      답변삭제모달: false,
      답변저장모달: false,
      isEditing: false, // 수정 모드 여부
      answer: '', // 답변 내용을 저장할 변수
    };
  },
  methods: {
    navigateToQandA() {
            this.$router.push('/QandA');
        },
    // 답변 수정 모드 활성화
    startEditing() {
      this.isEditing = true;
      this.답변수정모달 = false;
    },
    formatDate(dateString) {
      if (!dateString) return '-';
      const date = new Date(dateString);

      const year = date.getFullYear().toString().slice(-2); // 연도의 마지막 두 자리 추출
      const month = String(date.getMonth() + 1).padStart(2, '0'); // 월 (0부터 시작하므로 +1) 두 자리로 표시
      const day = String(date.getDate()).padStart(2, '0'); // 일 두 자리로 표시

      const hours = String(date.getHours()).padStart(2, '0'); // 시간 두 자리로 표시
      const minutes = String(date.getMinutes()).padStart(2, '0'); // 분 두 자리로 표시

      return `${year}.${month}.${day}\u00A0\u00A0\u00A0${hours}:${minutes}`;
    },

    // 답변 저장
    async 저장() {
      try {
        if (!this.answer.trim()) {
          this.답변저장모달 = false;
          Swal.fire({
                        imageUrl: require('@/assets/icons/Vector.svg'),
                        html: `<div style='font-size:1.5rem; margin-bottom: 1rem; font-weight:900;'>입력값을 확인하세요</div>
                     <div style='font-size:1rem; margin-top: 1rem; line-height: 1.5;'>
                      답변을 등록하시려면, <span style='color:#F93C65;'>답변내용</span>을 반드시 입력해 주세요`,
                        confirmButtonColor: '#5B67EC',
                        imageWidth: 55,
                        imageHeight: 55,
                    });
                    return false;}

    const answerData = {
      answer: this.answer.trim(),
    };

        // 서버에 수정된 답변 저장
        await this.$store.dispatch('editQandA', { id: this.noticeData.id, answer: answerData.answer });

        console.log('답변이 수정되었습니다:', this.answer);

        // 최신 데이터를 다시 가져와서 Vuex 상태를 업데이트
        await this.fetchQandA();

        this.isEditing = false;
        this.답변저장모달 = false;
      } catch (error) {
        console.error('답변 저장 실패:', error.response ? error.response.data : error.message);
      }
    },

    // 수정 취소
    취소() {
      this.isEditing = false;
      this.answer = this.noticeData.answer || '';
      console.log('답변 수정이 취소되었습니다');
    },

    // 최신 데이터를 서버에서 다시 가져오는 메서드
    async fetchQandA() {
      try {
        await this.$store.dispatch('getQandA');
        this.noticeData = this.$store.state.QandAlist.find(item => item.id === this.noticeData.id);
        this.answer = this.noticeData.answer || '';
      } catch (error) {
        console.error('Error fetching Q&A:', error);
      }
    },
  },

  // 페이지가 로드될 때 서버에서 최신 데이터를 가져옴
  async created() {
    if (this.$route.query.itemData) {
      this.noticeData = JSON.parse(this.$route.query.itemData);
      this.answer = this.noticeData.answer || '';

      // 페이지가 로드될 때 서버에서 최신 데이터를 다시 가져옴
      await this.fetchQandA();
    } else {
      this.$router.push("/QandA");
    }
  },
  
};
</script>



<style>
.qandaTable {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 1.125rem;
  overflow: hidden;
  background-color: white;
  border: 1px solid #6c757d;
}
.qandaTable td:first-child {
  border: 1px solid #eaeaea;
  padding: 0.625rem;
  text-align: center; /* 텍스트 가운데 정렬 */
  vertical-align: middle; /* 수직 가운데 정렬 */
}
.qandaTable td:last-child {
  border: 1px solid #eaeaea;
  padding-left: 1rem;
}

.qandaTable tr:last-child td:first-child {
  border-bottom-left-radius: 1.125rem;
}
.qandaTable tr:last-child td:last-child {
  border-bottom-right-radius: 1.125rem;
}
.qandaTable tr:first-child td:first-child {
  border-top-left-radius: 1.125rem;
}
.qandaTable tr:first-child td:last-child {
  border-top-right-radius: 1.125rem;
}
.qandaTable .title-row td {
  border-bottom: solid 1px #eaeaea;
}
.qandaTable .answer-row td {
  border-top: double 1px #eaeaea;
  padding: 1rem; /* 셀 패딩을 없애서 텍스트 영역이 꽉 차도록 함 */
  height: 25vh;
}
.qandaTable .question-row td:last-child {
  padding: 1rem; /* 셀 패딩을 없애서 텍스트 영역이 꽉 차도록 함 */
  vertical-align: top; /* 위쪽 정렬 */
  text-align: left; /* 텍스트 왼쪽 정렬 */
}
.large-cell {
  height: 25vh !important;
}
.answer-textarea {
  width: 100%;
  height: 100%;
  padding: 0.3rem;
  font-size: 1rem;
  border: none;
  outline: none;
  box-sizing: border-box;
  resize: none;
  background-color: transparent;
  overflow: hidden; 
  cursor : text;
}
.answer-textarea.inactive {
  pointer-events: none;
  cursor: default;
  overflow: hidden; 
}
</style>
