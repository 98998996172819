<template>
    <div class="uiSection">
        <div class="contentSection">
            <div class="contentFrist mx-4 my-3">
                <h4 class="contentTitle">주차장 도면 관리</h4>
                <button class="contentClose" @click="showAlertModal">X</button>
            </div>

            <b-modal v-model="alertModal" title="경고" hide-footer hide-header centered>
                <div class="modalContents">
                    <div class="modalImageWrapper">
                        <img src="@/assets/icons/Vector.svg" alt="" class="modalCar">
                    </div>
                    <div class="modalTextWrapper">
                        <p class="mainAnswer">주차장 도면 수정을 취소하시겠습니까?</p>
                        <p>변경된 사항은 저장되지 않습니다.</p>
                    </div>

                    <div class="modalBtn">
                        <b-button class="modal-blue-btn" @click="confirmNavigateBack">네</b-button>
                        <b-button class="modal-red-btn" @click="alertModal = false">아니오</b-button>
                    </div>
                </div>
            </b-modal>

            <div class="contentSecond mx-4">

                <div class="contentTable pt-3 pb-2">
                    <!-- 그리드 생성 -->
                    <div v-for="rowIndex in verticalCount" :key="'row-' + rowIndex" class="layoutRow">
                        <div v-for="colIndex in horizontalCount" :key="'cell-' + rowIndex + '-' + colIndex"
                            @click="handleCellClick(rowIndex - 1, colIndex - 1)" :class="{
                                selected: selectedCell && selectedCell.row === (rowIndex - 1) && selectedCell.col === (colIndex - 1),
                                outline: selectedCell && selectedCell.row === (rowIndex - 1) && selectedCell.col === (colIndex - 1)
                            }" class="layoutCell">
                            <!-- 이미지 또는 상태 표시 -->
                            <div v-if="getCellImage(rowIndex - 1, colIndex - 1)" class="checkedCell">
                                <img :src="getCellImage(rowIndex - 1, colIndex - 1).src" :style="getCellImage(rowIndex - 1, colIndex - 1).isDefault
                                    ? 'width: 30%; height: 30%; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);'
                                    : 'width: 100%; height: 100%;'" />
                            </div>
                            <!-- 좌표 정보 출력 -->
                            <!-- <span>{{ getRowLetter(colIndex  - 1) }}-{{ rowIndex - 1 }}</span> -->
                        </div>
                        <h5 style="color:white">라</h5>
                    </div>
                </div>

                <div v-if="isPanelVisible" class="contentPanel">
                    <button class="panelClose" @click="closePanel">X</button>
                    <div class="panelAll">
                        <div class="panelDiv">
                            <p><strong>주차장 이름</strong></p>
                            <input type="text" v-model="clickedData.name" class="clickedInput" />
                        </div>
                        <div class="panelDiv">
                            <p><strong>위치 좌표</strong></p>
                            <input type="text" v-model="clickedData.position" class="clickedInput" />
                        </div>
                        <div class="panelDiv">
                            <p><strong>자리 타입</strong></p>
                            <div class="radioOption">
                                <input type="radio" v-model="selectedType" name="spaceType" value="DEVICE" />
                                <span>기기설치</span>
                            </div>
                            <div class="radioOption">
                                <input type="radio" v-model="selectedType" name="spaceType" value="SEAT" />
                                <span>일반자리</span>
                            </div>
                            <div class="radioOption">
                                <input type="radio" v-model="selectedType" name="spaceType" value="ENTRANCE" />
                                <span>입/출구</span>
                            </div>
                            <div class="radioOption">
                                <input type="radio" v-model="selectedType" name="spaceType" value="ROAD" />
                                <span>도로</span>
                            </div>
                        </div>

                        <!-- 기기설치일 때만 나머지 항목들이 활성화 되도록 -->
                        <div>
                            <div class="panelDiv">
                                <p><strong>MAC 주소</strong></p>
                                <input v-model="macAddressInput" type="text" class="clickedInput"
                                    :disabled="selectedType !== 'DEVICE'"
                                    :class="{ disabledInput: selectedType !== 'DEVICE' }" />
                            </div>
                            <div class="panelDiv">
                                <p><strong>기기자리 ID</strong></p>
                                <input v-model="deviceIdInput" type="text" class="clickedInput"
                                    :disabled="selectedType !== 'DEVICE'"
                                    :class="{ disabledInput: selectedType !== 'DEVICE' }" />
                            </div>

                            <!-- 주차 방향 선택 -->
                            <div class="panelDiv">
                                <p><strong>주차 방향</strong></p>
                                <div class="radioOption">
                                    <input type="radio" v-model="selectedDirection" name="batch" value="EAST"
                                        :disabled="selectedType !== 'DEVICE'"
                                        :class="{ disabledInput: selectedType !== 'DEVICE' }" />
                                    <span>동쪽</span>
                                </div>
                                <div class="radioOption">
                                    <input type="radio" v-model="selectedDirection" name="batch" value="WEST"
                                        :disabled="selectedType !== 'DEVICE'"
                                        :class="{ disabledInput: selectedType !== 'DEVICE' }" />
                                    <span>서쪽</span>
                                </div>
                                <div class="radioOption">
                                    <input type="radio" v-model="selectedDirection" name="batch" value="NORTH"
                                        :disabled="selectedType !== 'DEVICE'"
                                        :class="{ disabledInput: selectedType !== 'DEVICE' }" />
                                    <span>북쪽</span>
                                </div>
                                <div class="radioOption">
                                    <input type="radio" v-model="selectedDirection" name="batch" value="SOUTH"
                                        :disabled="selectedType !== 'DEVICE'"
                                        :class="{ disabledInput: selectedType !== 'DEVICE' }" />
                                    <span>남쪽</span>
                                </div>
                            </div>


                            <div class="panelDiv">
                                <p><strong>시작 시간</strong></p>
                                <input type="number" class="clickedInput" v-model="startHour" min="0" max="23"
                                    :disabled="selectedType !== 'DEVICE'"
                                    :class="{ disabledInput: selectedType !== 'DEVICE' }" />
                                <span style="margin-right: 1rem;">시</span>
                                <input type="number" class="clickedInput" v-model="startMinute" min="0" max="59"
                                    :disabled="selectedType !== 'DEVICE'"
                                    :class="{ disabledInput: selectedType !== 'DEVICE' }" />
                                <span>분</span>
                            </div>
                            <div class="panelDiv">
                                <p><strong>종료 시간</strong></p>
                                <input type="number" class="clickedInput" v-model="endHour" min="0" max="23"
                                    :disabled="selectedType !== 'DEVICE'"
                                    :class="{ disabledInput: selectedType !== 'DEVICE' }" />
                                <span style="margin-right: 1rem;">시</span>
                                <input type="number" class="clickedInput" v-model="endMinute" min="0" max="59"
                                    :disabled="selectedType !== 'DEVICE'"
                                    :class="{ disabledInput: selectedType !== 'DEVICE' }" />
                                <span>분</span>
                            </div>
                        </div>
                        <button class="tempSave" @click="tempSave">임시저장</button>

                    </div>
                </div>
                <div v-else class="buttonPanel">
                    <button class="blue-btn" @click="saveToServer">도면 저장</button>
                    <button class="red-btn" @click="deleteLayout = true">도면 재설정/삭제</button>
                </div>
            </div>
        </div>
        <b-modal v-model="deleteLayout" hide-footer hide-header centered>
            <div class="modalContents">
                <div class="modalImageWrapper">
                    <img src="@/assets/icons/Vector.svg" alt="" class="modalCar" />
                </div>
                <div class="modalTextWrapper">
                    <p class="mainAnswer">도면을 재설정 또는 삭제하시겠습니까?</p>
                </div>
                <div class="modalBtn">
                    <b-button class="modal-blue-btn" @click="navigateToDevicePopup">재설정</b-button>
                    <b-button class="modal-red-btn" @click="onlyDeletelayout">삭제</b-button>
                </div>
            </div>
        </b-modal>
    </div>

</template>


<script>
import Swal from 'sweetalert2';

export default {
    data() {
        return {
            horizontalCount: 0,
            verticalCount: 0,
            clickedData: {
                name: '',
                position: '',
                type: '',
                batch: ''
            },
            selectedCell: null,
            selectedType: null,
            selectedDirection: null,
            savedCells: [],
            originalCells: [],
            isPanelVisible: true,
            macAddressInput: '',
            deviceIdInput: '',
            startHour: '',
            startMinute: '',
            endHour: '',
            endMinute: '',
            cells: [],
            alertModal: false,
            errorMessage: '',
            deleteLayout: false,
        };
    },
    created() {
        const clickedData = this.$route.query.clickedData;
        console.log("데이터", clickedData) // clickedDate의

        if (clickedData) {
            this.clickedData = JSON.parse(clickedData);

            // 기기가 설치된 경우 도면 데이터를 서버에서 불러옴
            if (this.clickedData.isInstalledDevice) {
                this.loadParkingLayout(this.clickedData.id).then(() => {
                    // 도면 데이터 로딩이 끝난 후 horizontalCount와 verticalCount 설정
                    const xValues = this.savedCells.map(cell => cell.x);  // x 값들 가져오기
                    const yValues = this.savedCells.map(cell => cell.y);  // y 값들 가져오기

                    // x는 0부터 시작하므로 최대값 + 1
                    this.horizontalCount = Math.max(...xValues) + 1;

                    // y는 -1부터 시작하므로 최소값을 찾아 조정
                    const yMin = Math.min(...yValues);
                    this.verticalCount = Math.max(...yValues) - yMin + 1; // y의 범위에 맞게 계산

                    console.log('불러온 데이터를 기반으로 계산된 horizontalCount:', this.horizontalCount);
                    console.log('불러온 데이터를 기반으로 계산된 verticalCount:', this.verticalCount);

                    // CSS 변수에 설정
                    document.documentElement.style.setProperty('--horizontalCount', this.horizontalCount);
                    document.documentElement.style.setProperty('--verticalCount', this.verticalCount);

                    // originalCells에 저장 (수정 전 원본 데이터를 보존)
                    this.originalCells = [...this.savedCells];
                });
            }
        }

        // 기기가 설치되지 않은 경우 사용자에게 가로/세로를 입력받아 테이블을 생성
        if (!this.clickedData || !this.clickedData.isInstalledDevice) {
            this.horizontalCount = parseInt(this.$route.query.horizontalCount) || 0;
            this.verticalCount = parseInt(this.$route.query.verticalCount) || 0;

            document.documentElement.style.setProperty('--horizontalCount', this.horizontalCount);
            document.documentElement.style.setProperty('--verticalCount', this.verticalCount);

            console.log('수동으로 입력된 horizontalCount:', this.horizontalCount);
            console.log('수동으로 입력된 verticalCount:', this.verticalCount);
        }
    }
    ,
    mounted() {
        this.adjustGridSize();
        window.addEventListener('resize', this.adjustGridSize);
        // if (this.clickedData.isInstalledDevice) {
        //     this.loadParkingLayout(this.clickedData.id);
        // }
    },
    methods: {
        getRowLetter(index) {
            let letter = '';
            while (index >= 0) {
                letter = String.fromCharCode((index % 26) + 65) + letter;
                index = Math.floor(index / 26) - 1;
            }
            return letter;
        },
        handleCellClick(row, col) {
            // 이전 셀의 데이터를 자동 저장
            if (this.selectedCell) {
                this.autoSaveSelectedCell();
            }

            // 새로 클릭한 셀의 데이터를 로드
            const savedCell = this.savedCells.find(cell => cell.x === col && cell.y === row);
            if (savedCell) {
                // 기존 데이터가 있으면 해당 데이터를 패널에 로드
                this.selectedCell = { row, col };
                this.selectedType = savedCell.type;
                this.selectedDirection = savedCell.batch;
                this.macAddressInput = savedCell.macAddress || '';
                this.deviceIdInput = savedCell.deviceId || '';

                // 시간 데이터를 분리해서 적용 (있을 경우에만)
                if (savedCell.availableStartTime) {
                    const [startHour, startMinute] = savedCell.availableStartTime.split(':');
                    this.startHour = startHour;
                    this.startMinute = startMinute;
                } else {
                    this.startHour = '';
                    this.startMinute = '';
                }

                if (savedCell.availableEndTime) {
                    const [endHour, endMinute] = savedCell.availableEndTime.split(':');
                    this.endHour = endHour;
                    this.endMinute = endMinute;
                } else {
                    this.endHour = '';
                    this.endMinute = '';
                }
            } else {
                // 저장된 데이터가 없을 경우 초기화
                this.selectedCell = { row, col };
                this.selectedType = null;
                this.selectedDirection = null;
                this.macAddressInput = '';
                this.deviceIdInput = '';
                this.startHour = '';
                this.startMinute = '';
                this.endHour = '';
                this.endMinute = '';
            }
            this.isPanelVisible = true;

            this.clickedData.position = `${this.getRowLetter(row)}-${col}`;
        },
        autoSaveSelectedCell() {
            if (!this.selectedCell || !this.selectedType) {
                return; // 셀과 타입이 선택되지 않았으면 저장하지 않음
            }

            // 기존 셀 데이터 찾기 (기존 데이터가 있으면 기존 값을 유지하기 위해)
            const existingCell = this.savedCells.find(cell => cell.x === this.selectedCell.col && cell.y === this.selectedCell.row);

            // 시간을 올바른 형식으로 변환하여 저장
            const formattedStartTime = `${String(this.startHour).padStart(2, '0')}:${String(this.startMinute).padStart(2, '0')}`;
            const formattedEndTime = `${String(this.endHour).padStart(2, '0')}:${String(this.endMinute).padStart(2, '0')}`;

            const cellData = {
                x: this.selectedCell.col,  // x 좌표는 열에 해당
                y: this.selectedCell.row,  // y 좌표는 행에 해당
                type: this.selectedType,  // 선택된 타입 (반드시 새로운 값)
                batch: this.selectedType === 'DEVICE' ? (this.selectedDirection || (existingCell && existingCell.batch)) : null,  // DEVICE일 경우 방향 유지
                macAddress: this.selectedType === 'DEVICE' ? (this.macAddressInput || (existingCell && existingCell.macAddress)) : null,  // DEVICE일 경우 MAC 주소 유지
                deviceId: this.selectedType === 'DEVICE' ? (this.deviceIdInput || (existingCell && existingCell.deviceId)) : null,  // DEVICE일 경우 Device ID 유지
                availableStartTime: this.selectedType === 'DEVICE' ? (formattedStartTime || (existingCell && existingCell.availableStartTime)) : null,  // 시작 시간 유지
                availableEndTime: this.selectedType === 'DEVICE' ? (formattedEndTime || (existingCell && existingCell.availableEndTime)) : null  // 종료 시간 유지
            };

            // 해당 좌표에 맞는 셀이 이미 저장되어 있는지 확인
            const existingCellIndex = this.savedCells.findIndex(
                cell => cell.x === this.selectedCell.col && cell.y === this.selectedCell.row
            );

            if (existingCellIndex !== -1) {
                // 기존 셀을 업데이트 (기존 값 유지 로직이 반영된 데이터로)
                this.savedCells.splice(existingCellIndex, 1, cellData);
            } else {
                // 새로운 셀을 추가
                this.savedCells.push(cellData);
            }

            console.log('자동 저장된 데이터:', this.savedCells);
        }
        ,

        getCellImage(row, col) {
            // 선택된 셀일 때 처리
            if (this.selectedCell && this.selectedCell.row === row && this.selectedCell.col === col) {
                if (!this.selectedType) {
                    return { src: require('@/assets/icons/checkedCell.svg'), isDefault: true };
                }
                // 선택된 타입에 따라 이미지 반환
                return this.getSelectedCellImage(this.selectedType, this.selectedDirection);
            }

            // 저장된 셀에 대한 이미지 처리 (저장된 셀은 변경되지 않음)
            const savedCell = this.savedCells.find(cell => cell.x === col && cell.y === row);
            if (savedCell) {
                return this.getSelectedCellImage(savedCell.type, savedCell.batch);
            }
            return null;
        }

        ,

        getSelectedCellImage(type, batch) {
            if (type === 'DEVICE') {
                switch (batch) {
                    case 'EAST':
                        return { src: require('@/assets/icons/deviceInstall.svg'), isDefault: false };
                    case 'WEST':
                        return { src: require('@/assets/icons/West.svg'), isDefault: false };
                    case 'NORTH':
                        return { src: require('@/assets/icons/North.svg'), isDefault: false };
                    case 'SOUTH':
                        return { src: require('@/assets/icons/South.svg'), isDefault: false };
                    default:
                        return { src: require('@/assets/icons/deviceInstall.svg'), isDefault: false };
                }
            }
            switch (type) {
                case 'SEAT':
                    return { src: require('@/assets/icons/regularSpace.svg'), isDefault: false };
                case 'ENTRANCE':
                    return { src: require('@/assets/icons/entranceExit.svg'), isDefault: false };
                case 'ROAD':
                    return null;
                default:
                    return null;
            }
        },
        validateInputs() {
            const startHour = this.startHour !== '' ? parseInt(this.startHour, 10) : null;
            const startMinute = this.startMinute !== '' ? parseInt(this.startMinute, 10) : null;
            const endHour = this.endHour !== '' ? parseInt(this.endHour, 10) : null;
            const endMinute = this.endMinute !== '' ? parseInt(this.endMinute, 10) : null;
            const clickedStart = new Date(`1970-01-01T${this.clickedData.operatingTime}`);
            const clickedEnd = new Date(`1970-01-01T${this.clickedData.closingTime}`);
            const availableStart = new Date(`1970-01-01T${String(this.startHour).padStart(2, '0')}:${String(this.startMinute).padStart(2, '0')}`);
            const availableEnd = new Date(`1970-01-01T${String(this.endHour).padStart(2, '0')}:${String(this.endMinute).padStart(2, '0')}`);

            if (this.selectedType === 'DEVICE') {
                if (!this.macAddressInput || !this.deviceIdInput || !this.selectedDirection) {
                    this.showAlert('입력값을 확인하세요', '기기설치를 선택한 경우, 필수항목을 입력해 주세요.', '필수항목');
                    return false;
                }
                if (this.startHour === '' || this.startMinute === '' || this.endHour === '' || this.endMinute === '') {
                    this.showAlert('입력값을 확인하세요', ' <span style="color:#F93C65;">시작시간</span>과  <span style="color:#F93C65;">종료시간</span>은 필수입력 항목입니다');
                    return false;
                }
                if ((startHour < 0 || startHour > 23) || (startMinute < 0 || startMinute > 59) || (endHour < 0 || endHour > 23) || (endMinute < 0 || endMinute > 59)) {
                    this.showAlert('입력값을 확인하세요', '시작시간과 종료시간은 23시 59분을 초과할 수 없습니다', '23시 59분');
                    return false;
                }
                if ((endHour === 0 && endMinute === 0)) {
                    this.showAlert('입력값을 확인하세요', '종료시간은 00시 00분일 수 없습니다', '00시 00분');
                    return false;
               }
               if (availableStart.getTime() === availableEnd.getTime()) {
                    this.showAlert('입력값을 확인하세요', ' <span style="color:#F93C65;">시작시간</span>과  <span style="color:#F93C65;">종료시간</span>은 같을 수 없습니다');
                    return false;
                }
                // 운영 시간 범위 확인
                const operatingTime = `(${this.clickedData.operatingHour}:${this.clickedData.operatingMinute}~${this.clickedData.closingHour}:${this.clickedData.closingMinute})`;
                if (availableStart < clickedStart || availableEnd > clickedEnd) {
                    this.showAlert(
                        '입력값을 확인하세요',
                        `시작시간과 종료시간은 <br> 운영 시간 ${operatingTime} 범위 내에 있어야 합니다`,
                        operatingTime
                    );
                    return false;
                }
            }
            return true;
        },

        showAlert(title, message, highlightText) {
            const highlightedMessage = message.replace(highlightText, `<span style='color:#F93C65;'>${highlightText}</span>`);
            Swal.fire({
                imageUrl: require('@/assets/icons/Vector.svg'),
                html: `<div style='font-size:1.5rem; margin-bottom: 1rem; font-weight:900;'>${title}</div><div style='font-size:1rem; margin-top: 1rem;'>${highlightedMessage}</div>`,
                confirmButtonText: '확인',
                confirmButtonColor: '#5B67EC',
                imageWidth: 55,
                imageHeight: 55,
            });
        }
        ,
        tempSave() {
            if (!this.selectedCell || !this.selectedType) {
                alert("셀과 타입을 선택하세요!");
                return;
            }

            // 시간을 올바른 형식으로 변환하여 저장
            const formattedStartTime = `${String(this.startHour).padStart(2, '0')}:${String(this.startMinute).padStart(2, '0')}`;
            const formattedEndTime = `${String(this.endHour).padStart(2, '0')}:${String(this.endMinute).padStart(2, '0')}`;

            const cellData = {
                x: this.selectedCell.col,  // x 좌표는 열에 해당
                y: this.selectedCell.row,  // y 좌표는 행에 해당
                type: this.selectedType,  // 선택된 타입
                batch: this.selectedType === 'DEVICE' ? this.selectedDirection : null,  // DEVICE일 경우 방향 저장
                macAddress: this.selectedType === 'DEVICE' ? this.macAddressInput || null : null,  // DEVICE일 경우 MAC 주소 저장
                deviceId: this.selectedType === 'DEVICE' ? this.deviceIdInput || null : null,  // DEVICE일 경우 Device ID 저장
                availableStartTime: this.selectedType === 'DEVICE' ? formattedStartTime : null,  // DEVICE일 경우 시작 시간 저장
                availableEndTime: this.selectedType === 'DEVICE' ? formattedEndTime : null  // DEVICE일 경우 종료 시간 저장
            };

            // 해당 좌표에 맞는 셀이 이미 저장되어 있는지 확인
            const existingCellIndex = this.savedCells.findIndex(
                cell => cell.x === this.selectedCell.col && cell.y === this.selectedCell.row
            );

            if (existingCellIndex !== -1) {
                // 기존 셀을 업데이트
                this.savedCells.splice(existingCellIndex, 1, cellData);
            } else {
                // 새로운 셀을 추가
                this.savedCells.push(cellData);
            }

            console.log('임시 저장된 데이터:', this.savedCells);
            this.closePanel();  // 패널 닫기
        }
        ,
        async saveToServer() {
            if (!this.validateInputs()) {
                console.log('validateInputs에서 실패');
                return;
            }
            const clickedData = JSON.parse(this.$route.query.clickedData);

            // 수정된 셀들만 필터링
            const updatedCells = this.savedCells.filter(cell => {
                const existingCell = this.originalCells.find(original => original.x === cell.x && original.y === cell.y);
                if (!existingCell) {
                    // 새로 추가된 셀인 경우
                    return true;
                }
                // 기존 셀과 비교하여 수정된 경우
                return (
                    existingCell.type !== cell.type ||
                    existingCell.batch !== cell.batch ||
                    existingCell.macAddress !== cell.macAddress ||
                    existingCell.deviceId !== cell.deviceId ||
                    existingCell.availableStartTime !== cell.availableStartTime ||
                    existingCell.availableEndTime !== cell.availableEndTime
                );
            });

            // 서버에 전송할 데이터 구성
            const serverData = {
                maxX: this.horizontalCount,
                maxY: this.verticalCount,
                list: updatedCells.map(cell => ({
                    macAddress: cell.macAddress || null,
                    deviceId: cell.deviceId || null,
                    x: cell.x,
                    y: cell.y,
                    type: cell.type,
                    batch: cell.type === 'DEVICE' ? cell.batch : null,
                    availableStartTime: cell.type === 'DEVICE' ? cell.availableStartTime : null,
                    availableEndTime: cell.type === 'DEVICE' ? cell.availableEndTime : null,
                }))
            };

            try {
                if (updatedCells.length > 0) {
                    console.log('서버로 전송될 데이터:', serverData);
                    await this.$store.dispatch('settingLayout', {
                        id: clickedData.id,
                        layoutData: serverData,
                    });
                    console.log('도면 저장 성공');
                    // 저장 후 수정된 데이터를 다시 불러옴
                    await this.loadParkingLayout(clickedData.id);
                    this.$router.push({
                        name: 'parkingList',
                        query: { clickedData: JSON.stringify(clickedData) } // 필요한 데이터를 전달
                    });
                } else {
                    alert('수정된 데이터가 없습니다.');
                }
            } catch (error) {
                console.error('도면 저장 중 오류 발생:', error);
                alert('도면 저장 중 오류가 발생했습니다.');
            }
        },

        closePanel() {
            this.isPanelVisible = false;
        },
        showAlertModal() {
            this.alertModal = true;
        },
        confirmNavigateBack() {
            this.alertModal = false;
            this.$router.push({
                name: 'parkingList',
                query: { clickedData: JSON.stringify(this.clickedData) },
            });
        },
        async navigateToDevicePopup() {
            try {
                const clickedData = JSON.parse(this.$route.query.clickedData);
                // 먼저 deleteLayout 호출
                await this.$store.dispatch('deleteLayout', clickedData.id);
                // deleteLayout이 성공적으로 호출된 후 페이지 이동
                this.$router.push({
                    path: 'parkingList',
                    query: {
                        horizontalCount: this.horizontalCount,
                        verticalCount: this.verticalCount,
                        clickedData: JSON.stringify(this.clickedData),
                        devicePopup: true
                    }
                });
            } catch (error) {
                console.error('삭제 중 오류 발생:', error);
                alert('삭제 중 오류가 발생했습니다.');
            }
        },
        async onlyDeletelayout() {
            try {
                const clickedData = JSON.parse(this.$route.query.clickedData);
                await this.$store.dispatch('deleteLayout', clickedData.id);
                this.$router.push('/parkingList');
            } catch (error) {
                console.error('삭제 중 오류 발생:', error);
                alert('삭제 중 오류가 발생했습니다.');
            }
        },
        adjustGridSize() {
            const container = this.$el.querySelector('.contentTable');
            const containerHeight = container.clientHeight;
            const containerWidth = container.clientWidth;

            if (this.verticalCount > 0 && this.horizontalCount > 0) {
                const borderSize = 1; // 셀의 경계선 크기 (px)

                // 경계선과 패딩을 고려한 셀 크기 계산
                const availableHeight = containerHeight - (this.verticalCount * borderSize);
                const availableWidth = containerWidth - (this.horizontalCount * borderSize);

                const cellHeight = availableHeight / this.verticalCount;
                const cellWidth = availableWidth / this.horizontalCount;

                // 가로 세로 중 더 작은 값을 선택하여 셀이 화면에 맞도록 조정
                const finalCellSize = Math.max(70, Math.min(cellHeight, cellWidth, 150));

                // 셀 크기 적용
                document.documentElement.style.setProperty('--cell-size', `${finalCellSize}px`);
            }
        },
        async loadParkingLayout(parkingId) {
            try {
                // Vuex 액션을 통해 데이터 불러오기
                const response = await this.$store.dispatch('fetchLayoutByParkingId', parkingId);

                // response 데이터 확인
                console.log('Response 데이터 확인:', response);

                this.savedCells = response.cells;  // 여기서 받아온 데이터를 savedCells에 저장

                if (this.savedCells.length > 0) {
                    // 첫 번째 셀을 선택 상태로 설정
                    const firstCell = this.savedCells[0];
                    this.selectedCell = { row: firstCell.y, col: firstCell.x };
                    this.selectedType = firstCell.type;
                    this.selectedDirection = firstCell.batch;
                    this.macAddressInput = firstCell.macAddress || '';
                    this.deviceIdInput = firstCell.deviceId || '';

                    // 시간 데이터를 불러와서 저장
                    if (firstCell.availableStartTime) {
                        const [startHour, startMinute] = firstCell.availableStartTime.split(':');
                        this.startHour = startHour;
                        this.startMinute = startMinute;
                    } else {
                        this.startHour = '';
                        this.startMinute = '';
                    }

                    if (firstCell.availableEndTime) {
                        const [endHour, endMinute] = firstCell.availableEndTime.split(':');
                        this.endHour = endHour;
                        this.endMinute = endMinute;
                    } else {
                        this.endHour = '';
                        this.endMinute = '';
                    }
                    this.isPanelVisible = true;
                    this.clickedData.position = `${this.getRowLetter(firstCell.y)}-${firstCell.x}`;

                    this.$nextTick(() => {
                        this.adjustGridSize(); // 그리드 크기 조정
                    });
                }

                console.log('불러온 셀 데이터:', this.savedCells);
            } catch (error) {
                console.error('도면 불러오기 중 오류:', error);
            }
        }
        ,
        getCell(rowIndex, colIndex) {
            console.log('getCell called with:', rowIndex, colIndex);
            const cell = this.savedCells.find(cell => cell.x === colIndex && cell.y === (rowIndex - 1));
            if (!cell) {
                console.warn("셀을 찾을 수 없습니다.");
            }
            return cell;
        }

    },
    watch: {
        selectedType(newType) {
            // 자리 타입이 "기기설치(DEVICE)"가 아닌 경우
            if (newType !== 'DEVICE') {
                this.macAddressInput = '';  // MAC 주소 초기화
                this.deviceIdInput = '';  // 기기 자리 ID 초기화
                this.selectedDirection = null;  // 주차 방향 초기화
                this.startHour = '';  // 시작 시간 초기화
                this.startMinute = '';  // 시작 분 초기화
                this.endHour = '';  // 종료 시간 초기화
                this.endMinute = '';  // 종료 분 초기화
            }

            // 기존 selectedCell의 타입도 업데이트
            if (this.selectedCell) {
                this.selectedCell.type = newType;
            }
        },
        horizontalCount(newVal) {
            document.documentElement.style.setProperty('--horizontalCount', newVal);
        },
        verticalCount(newVal) {
            document.documentElement.style.setProperty('--verticalCount', newVal);
        },
        savedCells(newVal) {
            // 필요한 경우 selectedCell 등을 업데이트하여 UI에 반영
            if (newVal.length > 0 && !this.selectedCell) {
                const firstCell = newVal[0];
                this.selectedCell = { row: firstCell.y, col: firstCell.x };
                this.selectedType = firstCell.type;
                this.selectedDirection = firstCell.batch;
            }
        }
    }
};
</script>

<style>
.uiSection {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;

}

.contentSection {
    background-color: white;
    border-radius: 18px;
    width: 90%;
    height: 90%;
    padding: 1.5rem;
}

.contentFrist {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.contentTitle {
    font-size: 30px;
    font-weight: bolder;
    margin-left: 2rem;
}


.layoutCell {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    /* 경계 고려 */
    width: var(--cell-size);
    height: var(--cell-size);
    border-right: 1px solid #6c757d;
    border-bottom: 1px solid #6c757d;
    margin: 0;
    padding: 0;
}

.layoutCell.selected {
    z-index: 10;
    /* 선택된 셀이 다른 요소보다 위로 보이게 설정 */
}

.layoutCell.outline {
    outline: 4px solid #5b67ec;
    /* 선택된 셀의 테두리를 강조 */
}

.layoutRow {
    height: 100%;
}

.layoutRow:first-child .layoutCell {
    border-left: 1px solid #6c757d;
}

.layoutRow .layoutCell:first-child {
    border-top: 1px solid #6c757d;
}

.layoutCell.selected {
    z-index: 10;
    /* 선택된 셀이 다른 요소보다 위로 보이게 설정 */
}

.layoutCell.outline {
    border: 2px solid #5b67ec;
    /* 선택된 셀의 테두리를 강조 */
}

.checkedCell {
    width: 100%;
    /* 셀 크기에 맞게 이미지 크기 조정 */
    height: 100%;
    /* 셀 크기에 맞게 이미지 크기 조정 */
    position: relative;
    /* 이미지가 셀 내부에서 제대로 위치하도록 함 */
}

.checkedCell img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* 이미지 비율을 유지하며 셀 크기에 맞게 채움 */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* 이미지를 셀의 가운데로 배치 */
}

.contentClose {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
}

.contentSecond {
    display: flex;
    flex-direction: row;
    height: 85%;
    max-width: 100%;
    margin-top: 2rem;
    gap: 1rem;
}

.contentTable {
    flex: 3;
    display: flex;
    justify-content: start;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    padding: 1rem;
    border: 1px solid black;
    border-radius: 18px;
    position: relative;
}

.contentTable::after {
    content: "";
    display: block;
    height: 3rem;
    /* 여백 높이 */
}



.contentPanel {
    flex: 2;
    overflow: hidden;
    border: 1px solid black;
    border-radius: 18px;
    position: relative;
    margin-right: 9rem;
    height: 100%;
}

.buttonPanel {
    flex: 2;
    display: flex;
}

.buttonPanel>button {
    width: 50%;
    height: 3rem;
    margin: 1rem;
}

.panelAll {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
}

.panelAll button {
    margin-left: auto;

}

.panelDiv {
    padding: 0.5rem;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    height: 4rem;
    align-items: center;
}

.radioOption {
    display: flex;
    align-items: center;
    margin-right: 10px;
    gap: 0.5rem;
}

.panelDiv p {
    margin: 0;
    width: 100px;
}

.panelDiv input {
    margin: 0;

}

.clickedInput {
    flex: 1;
}

.tempSave {
    background-color: #5b67ec;
    border: 1px solid #5b67ec;
    color: white;
    border-radius: 0.625rem;
    height: 40px;
    width: auto;
    padding-right: 0.625rem;
    padding-left: 0.625rem;
    margin-top: 1rem;
    transition: all 0.3s ease;
}

.tempSave:hover {
    background-color: white;
    color: #5b67ec;
}

.panelClose {
    background: none;
    border: none;
    position: absolute;

    top: 10px;
    right: 10px;

    font-size: 1rem;
    cursor: pointer;
}

.disabledInput {
    cursor: not-allowed;

}

.swal2-popup {
    padding: 20px;
    /* 팝업 창의 전체적인 패딩 조절 */
    border-radius: 18px;
}
</style>