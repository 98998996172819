<template>
  <meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=no">
  <div class="reservationView">
    <div class="contentSection">
      <div class="contentFrist my-3">
        <h4 class="contentTitle">주차 위치</h4>
      </div>

      <div class="layoutContainer" ref="layoutContainer">
        <div class="gridContainer" v-if="horizontalCount && verticalCount"
          :style="{ 'grid-template-columns': `repeat(${horizontalCount}, 1fr)` }">
          <div v-for="rowIndex in verticalCount" :key="'row-' + rowIndex" class="layoutRow">
            <div v-for="colIndex in horizontalCount" :key="'cell-' + rowIndex + '-' + colIndex"
              :class="['gridCell', getCellClass(rowIndex - 1, colIndex - 1)]">
              <div v-if="getCellImage(rowIndex - 1, colIndex - 1)" class="checkedCell">
                <img :src="getCellImage(rowIndex - 1, colIndex - 1).src" :style="getCellImage(rowIndex - 1, colIndex - 1).isDefault
                  ? 'width: 30%; height: 30%; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);'
                  : 'width: 100%; height: 100%;'" />
              </div>
              <div v-if="getReservedCell(rowIndex - 1, colIndex - 1)" class="checkedCell">
                <img :src="getDirectionIcon(getReservedCell(rowIndex - 1, colIndex - 1).batch)" :style="{
                  width: '100%',
                  height: '100%',
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)'
                }" />
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      parkingId: '1',  // 주차장 ID 설정
      horizontalCount: 0,
      verticalCount: 0,
      savedCells: [],  // 전체 도면 데이터
      reservedCells: [],  // 예약된 셀만 필터링하여 저장
    };
  },
  created() {
    this.loadParkingLayout();  // 서버로부터 도면 데이터 불러오기
  },
  methods: {
    async loadParkingLayout() {
      try {
        const response = await this.$store.dispatch('fetchLayoutByParkingId', this.parkingId);
        console.log('ss', response)
        this.savedCells = response.cells;

        // 예약된 셀만 필터링하여 저장
        this.reservedCells = this.savedCells.filter(cell => cell.status === 'WAITING'); //

        // 그리드 크기 계산
        const xValues = this.savedCells.map(cell => cell.x);
        const yValues = this.savedCells.map(cell => cell.y);
        this.horizontalCount = Math.max(...xValues) + 1;
        this.verticalCount = Math.max(...yValues) + 1;

        this.adjustCellSize();
      } catch (error) {
        console.error('도면 데이터를 불러오는 중 오류 발생:', error);
      }
    },
    adjustCellSize() {
      const maxCellSize = 50; // 셀의 최대 크기 (px)
      const minCellSize = 10; // 셀의 최소 크기 (px)

      // ref로 설정한 layoutContainer에 접근
      const container = this.$refs.layoutContainer;

      // 방어 코드 추가
      if (!container) {
        console.error('layoutContainer not found');
        return;
      }

      const containerWidth = container.clientWidth;
      const containerHeight = container.clientHeight;

      // 각 셀의 크기를 가로와 세로 중 작은 쪽에 맞게 조정
      const cellWidth = containerWidth / this.horizontalCount;
      const cellHeight = containerHeight / this.verticalCount;
      const finalCellSize = Math.min(cellWidth, cellHeight, maxCellSize);

      // 셀이 너무 작아지지 않도록 제한
      const cellSize = Math.max(finalCellSize, minCellSize);

      // CSS 변수로 설정
      document.documentElement.style.setProperty('--cell-size', `${cellSize}px`);
    }
    ,
    getReservedCell(row, col) {
      return this.reservedCells.find(cell => cell.x === col && cell.y === row);
    },
    getCellClass(row, col) {
      return this.getReservedCell(row, col) ? 'RESERVED' : '';
    },
    getCellImage(row, col) {
      const cell = this.savedCells.find(cell => cell.x === col && cell.y === row);
      if (!cell || !cell.type) return null;

      // 방향 정보와 타입에 따라 이미지 선택
      if (cell.type === 'DEVICE' && cell.batch) {
        return this.getSelectedCellImage(cell.type, cell.batch);
      }

      switch (cell.type) {
        case 'SEAT':
          return { src: require('@/assets/icons/regularSpace.svg'), isDefault: false };
        case 'ENTRANCE':
          return { src: require('@/assets/icons/entranceExit.svg'), isDefault: false };
        case 'ROAD':
          return null;
        default:
          return null;
      }
    },
    getSelectedCellImage(type, batch) {
      if (type === 'DEVICE') {
        switch (batch) {
          case 'EAST':
            return { src: require('@/assets/icons/deviceInstall.svg'), isDefault: false };
          case 'WEST':
            return { src: require('@/assets/icons/West.svg'), isDefault: false };
          case 'NORTH':
            return { src: require('@/assets/icons/North.svg'), isDefault: false };
          case 'SOUTH':
            return { src: require('@/assets/icons/South.svg'), isDefault: false };
          default:
            return { src: require('@/assets/icons/deviceInstall.svg'), isDefault: false };
        }
      }
      switch (type) {
        case 'SEAT':
          return { src: require('@/assets/icons/regularSpace.svg'), isDefault: false };
        case 'ENTRANCE':
          return { src: require('@/assets/icons/entranceExit.svg'), isDefault: false };
        case 'ROAD':
          return null;
        default:
          return null;
      }
    },
    getDirectionIcon(batch) {
      switch (batch) {
        case 'EAST':
          return require('@/assets/icons/selectedEast.svg');
        case 'WEST':
          return require('@/assets/icons/selectedWest.svg');
        case 'NORTH':
          return require('@/assets/icons/selectedNorth.svg');
        case 'SOUTH':
          return require('@/assets/icons/selectedSouth.svg');

      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.adjustCellSize(); // DOM이 렌더링된 후에만 크기 조정 실행
    });
    window.addEventListener('resize', () => {
      this.$nextTick(() => {
        this.adjustCellSize();
      });
    });
  }


};
</script>


<style scoped>
html,
body {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.reservationView {
  display: flex;
  flex-direction: column;
  align-items: center;

  overflow: hidden
}

.gridContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(50px, 1fr));
  width: 100%;
  gap: 0;
  max-width: 100%; /* 그리드 컨테이너의 너비를 제한 */
  max-height: 100%; 
}

.gridRow {
  display: flex;
}

.gridCell {
  width: 100%;
  position: relative;
  padding-bottom: 100%;
  border: 1px solid black;
  box-sizing: border-box;
  margin: 0;
  border-top: none;
  border-left: none;
}

.gridCell>div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;

}

.layoutRow:first-child .gridCell {
  border-left: 1px solid black;
}

.layoutRow .gridCell:first-child {
  border-top: 1px solid black;
}

.layoutContainer {
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  border: 1px solid #DFE1E5;
  margin: 0;
  padding: 1rem;
  overflow: auto;
}

.contentFrist {
  margin: 0;
}

.contentTitle {
  margin: 0;
}

.checkedCell {
  width: 100%;
  /* 셀 크기에 맞게 이미지 크기 조정 */
  height: 100%;
  /* 셀 크기에 맞게 이미지 크기 조정 */
  position: relative;
  /* 이미지가 셀 내부에서 제대로 위치하도록 함 */
}

.checkedCell img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* 이미지 비율을 유지하며 셀 크기에 맞게 채움 */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* 이미지를 셀의 가운데로 배치 */
}

@media only screen and (max-width: 600px) {
  .gridCell {
    height: calc(100vw / 10);
  }
}
</style>
