<template>
    <div class="contentUpper">
        <div class="fileSection">
            <button class="blue-btn" @click="triggerFileInput">주차장 등록</button>
            <button class="blue-btn" @click="downloadTemplate">양식다운로드</button>
            <button class="red-btn" @click="deleteModal = true">삭제</button>
        </div>

        <div class="filterSerchSection">
            <div class="filterFirst">
                <select v-model="selectKeyword" @change="emitFilterChange" class="custom-select"
                    style="width: 11.25rem;">
                    <option v-for="option in optionsKeyword" :key="option.value" :value="option.value">
                        {{ option.text }}
                    </option>
                </select>
                <div class="search-wrapper" >
                    <input v-model="search" @input="handleSearchInput"    class="search-input" type="text"
                        placeholder="검색어를 입력하세요" />
                    <button @click="emitFilterChange" type="submit" class="search-button">
                        <img src="../assets/icons/SearchIcon.svg" alt="Search">
                    </button>
                </div>
            </div>

            <div class="filterSecond">
                <!-- 옵션1: 지역 선택 -->
                <select v-model="selectedRegion" class="custom-select"   :class="{ 'moved-select1': !isParkingList }"  @change="emitFilterChange">
                    <option v-for="option in regionOptions" :key="option.value" :value="option.value">
                        {{ option.text }}
                    </option>
                </select>

                <!-- 옵션2: 시군구 선택 -->
                <select v-model="selectedCity" class="custom-select"   :class="{ 'moved-select': !isParkingList }" @change="emitFilterChange">
                    <option v-for="option in filteredOptions2" :key="option.value" :value="option.value">
                        {{ option.text }}
                    </option>
                </select>

                <button :class="['installedButton', { active: activeButton === 'button1' }]"
                    :style="{ visibility: isParkingList ? 'visible' : 'hidden' }" @click="toggleButtons('button1')">
                    기기 있음
                </button>
                <button :class="['installedButton', { active: activeButton === 'button2' }]"
                    :style="{ visibility: isParkingList ? 'visible' : 'hidden' }" @click="toggleButtons('button2')">
                    기기 없음
                </button>
            </div>
        </div>

        <!-- 삭제 모달 -->
        <b-modal v-model="deleteModal" title="삭제 확인" hide-footer hide-header centered>
            <div class="modalContents">
                <div class="modalImageWrapper">
                    <img src="@/assets/icons/Vector.svg" alt="" class="modalCar">
                </div>
                <div class="modalTextWrapper">
                    <p class="mainAnswer">주차장을 삭제하시겠습니까?</p>
                </div>

                <div class="modalBtn">
                    <b-button class="modal-blue-btn" @click="handleDelete">네</b-button>
                    <b-button class="modal-red-btn" @click="deleteModal = false">아니오</b-button>
                </div>
            </div>
        </b-modal>

        <!-- 숨겨진 파일 입력 요소 -->
        <input type="file" ref="fileInput" @change="handleFileUpload" style="display: none;">
    </div>
</template>

<script>
import * as XLSX from "xlsx"; // xlsx 라이브러리


export default {
    name: "searchFilter",
    props: {
        templateFileName: {
            type: String,
        },
        templateFilePath: {
            type: String,
            default: '/주차장 소유자 등록 양식.xlsx'
        },
        type: {
            type: String,
            required: true
        },
        regionOptions: Array,
        cityOptions: Object,
        isParkingList: Boolean,
    },
    data() {
        return {
            deleteModal: false,
            selectedRegion: null,
            selectedCity: null,
            activeButton: null,
            selectKeyword: '', // 기본값 설정
            search: '',
            uploadedItems: [],
            optionsKeyword: [], // 빈 배열로 시작
            activePanel: null,
            selectedItems: []
        };
    },
    computed: {
        filteredOptions2() {
            const defaultCityOption = [{ value: null, text: '시군구' }];
            if (!this.selectedRegion || !this.cityOptions[this.selectedRegion]) {
                return defaultCityOption;
            }
            return [...defaultCityOption, ...this.cityOptions[this.selectedRegion]];
        }
    },
    mounted() {
        this.setOptionsByType(); 
        this.emitFilterChange();
    },
    watch: {
    search() {
        this.emitFilterChange(); 
    },
    selectedRegion() {
    this.emitFilterChange();
  },
  selectedCity() {
    this.emitFilterChange();
  },
  activeButton() {
    this.emitFilterChange();
  }
},

    methods: {
        setOptionsByType() {
            if (this.type === 'type1') {
                this.optionsKeyword = [
                    { value: '', text: '선택없음', disabled: true },
                    { value: 'name', text: '주차장 이름' },
                    { value: 'address', text: '주차장 주소' }
                ];
            } else if (this.type === 'type2') {
                this.optionsKeyword = [
                    { value: '', text: '선택없음', disabled: true },
                    { value: 'address', text: '주차장 주소' },
                    { value: 'name', text: '주차장 구간' }
                ];
            } else {
                this.optionsKeyword = [
                    { value: '', text: '선택없음', disabled: true },
                    { value: 'default', text: '기본 옵션' }
                ];
            }
        },
        emitFilterChange() {
            this.currentPage = 1;
            // 이벤트를 상위 컴포넌트로 전달
            this.$emit('filter-change', {
                selectedRegion: this.selectedRegion,
                selectedCity: this.selectedCity,
                activeButton: this.activeButton,
                selectKeyword: this.selectKeyword,
                search: this.search
            });
        },
        handleSearchInput() {
            // 검색어가 한 글자여도 필터가 작동하도록 메서드 호출
            this.emitFilterChange();
        },
        handleDelete() {
            this.$emit('delete-items'); // 부모 컴포넌트로 이벤트 전달

            if (this.selectedItems && this.selectedItems.length > 0) {
                console.log('삭제할 항목들:', this.selectedItems); // 선택된 항목을 로그로 출력

                // 선택된 항목들을 순회하며 개별적으로 삭제
                const deletePromises = this.selectedItems.map(selectedItem => {
                    const isTemporaryParking = selectedItem.type === 'type2'; // 한시적 주차
                    const isOwnerParking = selectedItem.type === 'type3'; // 소유자 관리

                    if (isTemporaryParking) {
                        // 한시적 주차 리스트 삭제
                        console.log("삭제하려는 한시적 ID:", selectedItem.id);
                        return this.$store.dispatch('deleteCheckTempParking', selectedItem.id); // id로 개별 삭제
                    } else if (isOwnerParking) {
                        // 소유자 관리 삭제
                        console.log("삭제하려는 소유자", selectedItem.ownerId);
                        return this.$store.dispatch('deleteCheckOwnerList', selectedItem.ownerId); // ownerId와 id로 개별 삭제
                    } else {
                        // 일반 주차장 리스트 삭제
                        console.log("삭제하려는 주차장 IDcccc:", selectedItem.id);
                        return this.$store.dispatch('deleteCheckParkinglist', selectedItem.id); // id로 개별 삭제
                    }
                });

                // Promise.all로 모든 삭제 요청이 완료될 때까지 기다림
                Promise.all(deletePromises)
                    .then(() => {
                        console.log('모든 항목 삭제 완료');
                        this.$emit('refresh-list');  // 리스트 새로고침
                        this.deleteModal = false;    // 모달 닫기
                        this.selectedItems = [];     // 선택 항목 초기화
                    })
                    .catch(error => {
                        console.error(error);
                    });
            } else {
                this.deleteModal = false;  // 선택된 항목이 없을 때도 모달창 닫기
            }
        },
        toggleButtons(button) {
            if (this.activeButton === button) {
                this.activeButton = null;
            } else {
                this.activeButton = button;
            }
            this.emitFilterChange();
        },
        triggerFileInput() {
            this.$refs.fileInput.click();
        },
        downloadTemplate() {
            const link = document.createElement('a');
            link.href = this.templateFilePath;
            link.download = this.templateFileName;

            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
        },
        handleFileUpload(event) {
            const file = event.target.files[0]; // 파일 선택
            if (file) {
                const reader = new FileReader();

                reader.onload = (e) => {
                    const data = new Uint8Array(e.target.result);
                    const workbook = XLSX.read(data, { type: "array" });

                    // 첫 번째 시트의 데이터를 읽어옴
                    const firstSheetName = workbook.SheetNames[0];
                    const worksheet = workbook.Sheets[firstSheetName];

                    // 시트의 데이터를 JSON 형식으로 변환
                    const jsonData = XLSX.utils.sheet_to_json(worksheet);

                    // type에 따라 다른 처리를 진행
                    if (this.type === 'type1') {
                        // type1 처리: 주차장 리스트
                        const parkingInfoArray = jsonData.map(row => ({
                            no: row['No'] || '',  // 번호
                            parkingType: row['주차장 타입(실내, 실외)'] === '실내' ? 'INDOOR' : 'OUTDOOR',  // 주차장 타입
                            region: row['지역'] || '',  // 지역
                            city: row['시군구'] || '',  // 시군구
                            name: row['주차장명'] || '',  // 주차장명
                            address: row['상세주소'] || '',  // 상세주소
                            latitude: row['위도'] || 0,  // 위도
                            longitude: row['경도'] || 0,  // 경도
                            seats: row['주차면 수'] || 0,  // 주차면 수
                            firstFee: row['주차기본요금'] || 0,  // 주차기본요금
                            firstMinute: row['기본시간'] || 0,  // 기본시간
                            extraFee: row['추가요금'] || 0,  // 추가요금
                            extraMinute: row['추가시간'] || 0,  // 추가시간
                            limitFeeOfDay: row['일 최대 요금'] || 0,  // 일 최대 요금
                            contact: row['연락처'] || '',  // 연락처
                        }));

                        // FormData 객체 생성
                        const formData = new FormData();
                        formData.append('registerParkingInfo', JSON.stringify(parkingInfoArray));
                        formData.append('file', file);

                        // Vuex 액션 호출 (주차장 리스트 추가)
                        this.$store.dispatch('addParkingItems', formData)
                            .then(() => {
                                console.log('Parking items uploaded successfully');
                            })
                            .catch(error => {
                                console.error('Error uploading parking items:', error);
                            });

                    } else if (this.type === 'type2') {
                        // type2 처리: 한시적 주차 리스트
                        const tempParkingInfoArray = jsonData.map(row => ({
                            no: row['No'] || '',  // 번호
                            region: row['지역'] || '',  // 지역
                            city: row['시군구'] || '',  // 시군구
                            name: row['주차장명'] || '',  // 주차장명
                            address: row['상세주소'] || '',  // 상세주소
                            startDate: row['시작일'] || '',  // 시작일
                            endDate: row['종료일'] || '',  // 종료일
                            fee: row['주차요금'] || 0,  // 주차요금
                            contact: row['연락처'] || '',  // 연락처
                        }));

                        // FormData 객체 생성
                        const formData = new FormData();
                        formData.append('registerTempParkingInfo', JSON.stringify(tempParkingInfoArray));
                        formData.append('file', file);

                        // Vuex 액션 호출 (한시적 주차 리스트 추가)
                        this.$store.dispatch('addTempParkingItems', formData)
                            .then(() => {
                                console.log('Temporary parking items uploaded successfully');
                            })
                            .catch(error => {
                                console.error('Error uploading temporary parking items:', error);
                            });
                    }
                };

                reader.readAsArrayBuffer(file);
            }
        }


    },
};
</script>



<style>
.contentUpper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 3rem;
    margin-left: 6.25rem;
    margin-right: 6.25rem;
    flex-wrap: wrap;
}

.fileSection {
    display: flex;
    gap: 0.5rem;
}

.filterSerchSection {
    width: 30rem;
}

.installedButton {
    background-color: #ffff;
    color: #000;
    border: 1px solid #EAEAEA;
    border-radius: 8px;
    padding: 6px 1.25rem;
}

.installedButton.active {
    background-color: #EAEAEA;
    color: black;
}

.filterFirst {
    display: flex;
    gap: 0.625rem;
}

.filterSecond {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.4rem;
    margin-top: 0.625rem;
}

.filterSecond>* {
    flex: 1;
    max-width: 200px;
}

.blue-btn {
    border: 1px solid #5b67ec;
    color: #5b67ec;
    background-color: white;
    border-radius: 0.625rem;
    height: 40px;
    padding-right: 0.625rem;
    padding-left: 0.625rem;
}

.red-btn {
    border: 1px solid #F93c65;
    color: #F93c65;
    background-color: white;
    border-radius: 0.625rem;
    height: 40px;
    padding-right: 0.625rem;
    padding-left: 0.625rem;
}

.black-btn {
    border: 1px solid #6c757d;
    color: #6c757d;
    background-color: white;
    border-radius: 0.625rem;
    height: 40px;
    padding-right: 0.625rem;
    padding-left: 0.625rem;
}

.blue-btn:hover {
    background-color: #5b67ec;
    color: white;
}

.red-btn:hover {
    background-color: #F93c65;
    color: white;
}

.black-btn:hover {
    background-color: #6c757d;
    color: white;
}

.custom-select {
    width: 11.25rem;
    height: 40px;
    padding: 0.3rem 0.625rem;
    font-size: 1rem;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.5rem;
}

.custom-select:focus {
    outline: none;
}

.custom-select option {
    color: #000;
    background-color: #fff;
}

.uploadForm {
    margin-top: 1.25rem;
    padding: 1.25rem;
    border: 1px solid #eaeaea;
    border-radius: 0.625rem;
    background-color: #f9f9f9;
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
    width: 300px;
}

.search-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
}

.search-input {
    padding-right: 40px;
    width: 100%;
    height: 40px;
    padding: 0.3rem 0.625rem;
    font-size: 1rem;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.5rem;
}

.search-input:focus {
    outline: none;
}

.search-input option {
    color: #000;
    background-color: #fff;
}

.search-button {
    position: absolute;
    right: 0.625rem;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.125rem;
    color: #333;
}

.search-button:focus {
    outline: none;
}
.moved-select {
    position: absolute;
    right: 0;
}
.moved-select1 {
    position: absolute;
    right: 11.5rem;
}
</style>