<template>
  <div class="container">
    <div class="seat-description">
      <img src="@/assets/ic_patch_east.svg" />
      <a>선택가능</a>
      <img src="@/assets/Group 143726377.svg" />
      <a>선택불가</a>
      <img src="@/assets/ic_selected.svg" />
      <a style="margin-right: 0;">선택자리</a>
    </div>
    <div class="seat-content no-margin no-padding">
      <div class="grid-container" :style="{ 
        gridTemplateColumns: `repeat(${maxX}, 1fr)`,
         gridTemplateRows: `repeat(${maxY}, 1fr)` }">
        <div
          v-for="(lot, index) in parkingLots.flat()" 
          :key="index"
          class="grid-item"
          :style="{
            gridRow: lot.x + 1, 
            gridColumn: lot.y + 1,
            width: `${100 / maxX}-10vw`, 
            height: `${100 / maxX}-10vw`, 
          }" 
          @click="handleSelect(lot)"
          :class="{ 'selected': isSelectedLot(lot) }">
          <img 
          v-if="lotIcon(lot)" 
          :src="lotIcon(lot)" 
          class="item-src"
          :alt="`${lot.type} Icon`" />
          <img
            v-if="isSelectedLot(lot)"
            :src="selectedIcon"
            class="selected-overlay"
          />
          <div class="item-desc">
            <div style="font: .9rem sans-serif; text-align: center" >{{ getId(lot.deviceId)}}</div>
            <div class="desc-text">{{ formatAvailableTime(lot.availableStartTime, lot.availableEndTime) }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import ExitIcon from '../assets/ic_entry_exit.svg';
import noIcon from '../assets/Group 143726377.svg'; // 예약 불가 상태 아이콘
import eastIcon from '../assets/ic_patch_east.svg'; // EAST 상태 아이콘
import westIcon from '../assets/ic_patch_west.svg'; // WEST 상태 아이콘
import southIcon from '../assets/ic_patch_south.svg'; // SOUTH 상태 아이콘
import northIcon from '../assets/ic_patch_north.svg'; // NORTH 상태 아이콘
import selectedIcon from '../assets/ic_selected.svg';
import Swal from 'sweetalert2';

export default {
  data() {
    return {
      parkingLots: [],
      maxX: 0,
      maxY: 0,
      selectedLot: null,
      selectedIcon: selectedIcon,
    };
  },
  computed: {
    id() {
      return this.$route.query.id;
    },
    authToken() {
      return this.$route.query.authToken;
    },
  },
  methods: {
    getId(deviceId) {  
      return deviceId ? deviceId.split('-').slice(1, 3).join('-') : null;
    },
    formatAvailableTime(start, end) {
      if (start && end) {
        return `${start.slice(0, 5)} ~ ${end.slice(0, 5)}`;
      } else {
        return "";  // 시간이 없는 경우 표시
      }
    },
    handleSelect(lot) {
      if (lot.type === "DEVICE" && lot.status === 'WAITING') {
        this.selectedLot = lot;
      }
    },
    isSelectedLot(lot) {
      return this.selectedLot && this.selectedLot.x === lot.x && this.selectedLot.y === lot.y;
    },
    async fetchParkingLots() {
      if (!this.id || !this.authToken) return;
      const apiUrl = `https://api.simpleparking.co.kr/v1/parking/${this.id}/seats`;

      try {
        const response = await axios.get(apiUrl, {
          headers: {
            Authorization: `Bearer ${this.authToken}`,
          },
        });
        const data = response.data.list;
        
        this.parkingLots = data;
        this.maxX = Math.max(...data.flat().map(lot => lot.x)) + 1;
        this.maxY = Math.max(...data.flat().map(lot => lot.y)) + 1;
      } catch (error) {
        console.error('주차장 정보를 불러오는 중 오류 발생:', error);
      }
    },
    lotIcon(lot) {
      if (lot.type === 'DEVICE') {
        if (lot.status === 'WAITING') {
          switch (lot.batch) {
            case 'EAST':
              return eastIcon;
            case 'WEST':
              return westIcon;
            case 'SOUTH':
              return southIcon;
            case 'NORTH':
              return northIcon;
            default:
              return null;
          }
        } else {
          return noIcon;
        } 
      } else if (lot.type === 'SEAT') {
        return noIcon;  // SEAT일 때 예약 불가 아이콘
      } else if (lot.type === 'ROAD') {
        return null; // ROAD는 아이콘 없음
      } else if (lot.type === 'ENTRANCE') {
        return ExitIcon;
      }
      return null;
    },

    sendToAndroidApp() {
      if (this.selectedLot) {
        window.AndroidApp.receiveLotInfo(JSON.stringify(this.selectedLot));
      } else {
        Swal.fire({
          icon:'warning',
          html: `주차자리를 선택해주세요.`,
          confirmButtonText: `확인`,
          confirmButtonColor: '#5B67EC',
        });
      }
    },
  },
  mounted() {
    this.fetchParkingLots();    
    window.sendToAndroidApp = this.sendToAndroidApp;
  },
};
</script>

<style scoped>
/* 여기에 필요한 스타일 추가 */
.container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
}

.seat-description {
  padding: 1rem;
}

.seat-description img {
  width: 2rem;
  height: 2rem;
}

.seat-description a {
  font-size: .8rem;
  margin-left: .2rem;
  margin-right: 1rem;
}

.seat-content {
  width: 100vw;
  height: fit-content;
  overflow: auto; /* 스크롤 활성화 */
  background: whitesmoke;
}

.grid-container {
  display: grid;
  width: 100%;
  height: fit-content;
  padding: 5px;
}

.grid-item {
  min-height: 80px; 
  min-width: 80px; 
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.item-src {
  height: 95%; /* 각 칸의 높이 */
  width: 95%; /* 각 칸의 너비 */
}

.lotDiv {
  display: inline-block;
}

.selected-overlay {
  position: absolute;
  top: 0;  /* 상단에 위치 */
  left: 0;  /* 좌측에 위치 */
  height: 100%;  /* grid-item 크기에 맞게 조정 */
  width: 100%;  /* grid-item 크기에 맞게 조정 */
  z-index: 2;
}

.item-desc {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  top: 0;  /* 상단에 위치 */
  left: 0;  /* 좌측에 위치 */
  height: 100%;  /* grid-item 크기에 맞게 조정 */
  width: 100%;  /* grid-item 크기에 맞게 조정 */
  z-index:1;
  padding: .3rem;
}

.desc-text{
  text-align: center;
  font-size: .6rem;
}
</style>
