import axios from 'axios';
import store from './store/store';
import router from '@/router'; // Vue Router 사용

// Axios 기본 설정
// axios.defaults.baseURL = '/api';
axios.defaults.baseURL = 'https://api.simpleparking.co.kr';

// 요청 인터셉터: 토큰이 있으면 Authorization 헤더에 추가
axios.interceptors.request.use(
  (config) => {
    const token = store.state.token; // Vuex 상태에서 직접 가져오기
    console.log("Request URL:", config.url);
    if (config.url !== 'v1/auth') {
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 응답 인터셉터: 토큰이 만료된 경우 재발급
axios.interceptors.response.use(
  (response) => {
    return response; // 정상 응답은 그대로 처리
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      // 401 오류 발생 시 로그아웃 처리
      store.commit('logout'); // Vuex 상태 초기화 (로그아웃)
      router.push('/loginPage'); // 로그인 페이지로 이동
    }
    return Promise.reject(error);
  }
);


export default axios;
