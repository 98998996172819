<template>
  <div class="container">
    <div class="seat-description">
      <img src="@/assets/ic_patch_east.svg" />
      <a>선택가능</a>
      <img src="@/assets/Group 143726377.svg" />
      <a>선택불가</a>
      <img src="@/assets/ic_selected.svg" />
      <a style="margin-right: 0;">선택자리</a>
    </div>
    <div class="seat-content no-margin no-padding">
      <div class="grid-container" :style="{ 
        gridTemplateColumns: `repeat(${maxX}, 1fr)`, 
        gridTemplateRows: `repeat(${maxY}, 1fr)` }">
        <div
          v-for="(lot, index) in parkingLots.flat()" 
          :key="index"
          class="grid-item"
          :style="{
            gridRow: lot.x + 1, 
            gridColumn: lot.y + 1,
            width: `${100 / maxX}-10vw`, 
            height: `${100 / maxX}-10vw`, 
          }" 
          @click="handleSelect(lot)"
          :class="{ 'selected': isSelectedLot(lot) }">
          <img 
          v-if="lotIcon(lot)" 
          :src="lotIcon(lot)" 
          class="item-src"
          :alt="`${lot.type} Icon`" />
          <img
            v-if="isSelectedLot(lot)"
            :src="selectedIcon"
            class="selected-overlay"
          />
          <div class="item-desc">
            <div style="font: .9rem sans-serif; text-align: center" >{{ getId(lot.deviceId)}}</div>
            <div class="desc-text">{{ formatAvailableTime(lot.availableStartTime, lot.availableEndTime) }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import noIcon from '../assets/Group 143726377.svg'; // 예약 불가 상태 아이콘
import eastIcon from '../assets/ic_patch_east.svg'; // EAST 상태 아이콘
import westIcon from '../assets/ic_patch_west.svg'; // WEST 상태 아이콘
import southIcon from '../assets/ic_patch_south.svg'; // SOUTH 상태 아이콘
import northIcon from '../assets/ic_patch_north.svg'; // NORTH 상태 아이콘
import selectedIcon from '../assets/ic_selected.svg';
import Swal from 'sweetalert2';

export default {
  data() {
    return {
      parkingLot: [
        [ 
          {
              "macAddress": null,
              "deviceId": null,
              "x": 0,
              "y": 0,
              "type": "SEAT",
              "batch": null,
              "status": null,
              "availableStartTime": null,
              "availableEndTime": null
          },
          {
              "macAddress": null,
              "deviceId": null,
              "x": 1,
              "y": 0,
              "type": "ROAD",
              "batch": null,
              "status": null,
              "availableStartTime": null,
              "availableEndTime": null
          },
          {
              "macAddress": null,
              "deviceId": null,
              "x": 2,
              "y": 0,
              "type": "SEAT",
              "batch": null,
              "status": null,
              "availableStartTime": null,
              "availableEndTime": null
          }
        ],
        [
          {
              "macAddress": null,
              "deviceId": null,
              "x": 0,
              "y": 1,
              "type": "SEAT",
              "batch": null,
              "status": null,
              "availableStartTime": null,
              "availableEndTime": null
          },
          {
              "macAddress": null,
              "deviceId": null,
              "x": 1,
              "y": 1,
              "type": "ROAD",
              "batch": null,
              "status": null,
              "availableStartTime": null,
              "availableEndTime": null
          },
          {
              "macAddress": null,
              "deviceId": null,
              "x": 2,
              "y": 1,
              "type": "SEAT",
              "batch": null,
              "status": null,
              "availableStartTime": null,
              "availableEndTime": null
          },
        ],
        [
          {
              "macAddress": null,
              "deviceId": null,
              "x": 0,
              "y": 2,
              "type": "SEAT",
              "batch": null,
              "status": null,
              "availableStartTime": null,
              "availableEndTime": null
          },
          {
              "macAddress": null,
              "deviceId": null,
              "x": 1,
              "y": 2,
              "type": "ROAD",
              "batch": null,
              "status": null,
              "availableStartTime": null,
              "availableEndTime": null
          },
          {
              "macAddress": null,
              "deviceId": null,
              "x": 2,
              "y": 2,
              "type": "DEVICE",
              "batch": "NORTH",
              "status": "WAITING",
              "availableStartTime": "00:00:00",
              "availableEndTime": "20:00:00"
          },
        ]
      ],
      parkingLots: [
      [
            {
                "macAddress": "11235",
                "deviceId": "살레시오-A-0",
                "x": 0,
                "y": 0,
                "type": "DEVICE",
                "batch": "EAST",
                "status": "WAITING",
                "availableStartTime": "15:00:00",
                "availableEndTime": "18:00:00"
            },
            {
                "macAddress": null,
                "deviceId": null,
                "x": 1,
                "y": 0,
                "type": "DEVICE",
                "batch": "EAST",
                "status": "WAITING",
                "availableStartTime": null,
                "availableEndTime": null
            },
            {
                "macAddress": null,
                "deviceId": null,
                "x": 2,
                "y": 0,
                "type": "ROAD",
                "batch": null,
                "status": null,
                "availableStartTime": null,
                "availableEndTime": null
            },
            {
                "macAddress": null,
                "deviceId": null,
                "x": 3,
                "y": 0,
                "type": "ROAD",
                "batch": null,
                "status": null,
                "availableStartTime": null,
                "availableEndTime": null
            },
            {
                "macAddress": null,
                "deviceId": null,
                "x": 4,
                "y": 0,
                "type": "ROAD",
                "batch": null,
                "status": null,
                "availableStartTime": null,
                "availableEndTime": null
            },
            {
                "macAddress": null,
                "deviceId": null,
                "x": 5,
                "y": 0,
                "type": "ROAD",
                "batch": null,
                "status": null,
                "availableStartTime": null,
                "availableEndTime": null
            },
            {
                "macAddress": null,
                "deviceId": null,
                "x": 6,
                "y": 0,
                "type": "ROAD",
                "batch": null,
                "status": null,
                "availableStartTime": null,
                "availableEndTime": null
            },
            {
                "macAddress": null,
                "deviceId": null,
                "x": 7,
                "y": 0,
                "type": "DEVICE",
                "batch": "NORTH",
                "status": "WAITING",
                "availableStartTime": null,
                "availableEndTime": null
            }
        ],
        [
            {
                "macAddress": null,
                "deviceId": null,
                "x": 0,
                "y": 1,
                "type": "ROAD",
                "batch": null,
                "status": null,
                "availableStartTime": null,
                "availableEndTime": null
            },
            {
                "macAddress": null,
                "deviceId": null,
                "x": 1,
                "y": 1,
                "type": "ROAD",
                "batch": null,
                "status": null,
                "availableStartTime": null,
                "availableEndTime": null
            },
            {
                "macAddress": null,
                "deviceId": null,
                "x": 2,
                "y": 1,
                "type": "ROAD",
                "batch": null,
                "status": null,
                "availableStartTime": null,
                "availableEndTime": null
            },
            {
                "macAddress": null,
                "deviceId": null,
                "x": 3,
                "y": 1,
                "type": "ROAD",
                "batch": null,
                "status": null,
                "availableStartTime": null,
                "availableEndTime": null
            },
            {
                "macAddress": null,
                "deviceId": null,
                "x": 4,
                "y": 1,
                "type": "ROAD",
                "batch": null,
                "status": null,
                "availableStartTime": null,
                "availableEndTime": null
            },
            {
                "macAddress": null,
                "deviceId": null,
                "x": 5,
                "y": 1,
                "type": "ROAD",
                "batch": null,
                "status": null,
                "availableStartTime": null,
                "availableEndTime": null
            },
            {
                "macAddress": null,
                "deviceId": null,
                "x": 6,
                "y": 1,
                "type": "ROAD",
                "batch": null,
                "status": null,
                "availableStartTime": null,
                "availableEndTime": null
            },
            {
                "macAddress": null,
                "deviceId": null,
                "x": 7,
                "y": 1,
                "type": "DEVICE",
                "batch": "NORTH",
                "status": "WAITING",
                "availableStartTime": null,
                "availableEndTime": null
            }
        ],
        [
            {
                "macAddress": null,
                "deviceId": null,
                "x": 0,
                "y": 2,
                "type": "ROAD",
                "batch": null,
                "status": null,
                "availableStartTime": null,
                "availableEndTime": null
            },
            {
                "macAddress": null,
                "deviceId": null,
                "x": 1,
                "y": 2,
                "type": "ROAD",
                "batch": null,
                "status": null,
                "availableStartTime": null,
                "availableEndTime": null
            },
            {
                "macAddress": null,
                "deviceId": null,
                "x": 2,
                "y": 2,
                "type": "ROAD",
                "batch": null,
                "status": null,
                "availableStartTime": null,
                "availableEndTime": null
            },
            {
                "macAddress": null,
                "deviceId": null,
                "x": 3,
                "y": 2,
                "type": "ROAD",
                "batch": null,
                "status": null,
                "availableStartTime": null,
                "availableEndTime": null
            },
            {
                "macAddress": null,
                "deviceId": null,
                "x": 4,
                "y": 2,
                "type": "ROAD",
                "batch": null,
                "status": null,
                "availableStartTime": null,
                "availableEndTime": null
            },
            {
                "macAddress": null,
                "deviceId": null,
                "x": 5,
                "y": 2,
                "type": "ROAD",
                "batch": null,
                "status": null,
                "availableStartTime": null,
                "availableEndTime": null
            },
            {
                "macAddress": null,
                "deviceId": null,
                "x": 6,
                "y": 2,
                "type": "ROAD",
                "batch": null,
                "status": null,
                "availableStartTime": null,
                "availableEndTime": null
            },
            {
                "macAddress": null,
                "deviceId": null,
                "x": 7,
                "y": 2,
                "type": "ROAD",
                "batch": null,
                "status": null,
                "availableStartTime": null,
                "availableEndTime": null
            }
        ],
        [
            {
                "macAddress": null,
                "deviceId": null,
                "x": 0,
                "y": 3,
                "type": "ROAD",
                "batch": null,
                "status": null,
                "availableStartTime": null,
                "availableEndTime": null
            },
            {
                "macAddress": null,
                "deviceId": null,
                "x": 1,
                "y": 3,
                "type": "ROAD",
                "batch": null,
                "status": null,
                "availableStartTime": null,
                "availableEndTime": null
            },
            {
                "macAddress": null,
                "deviceId": null,
                "x": 2,
                "y": 3,
                "type": "ROAD",
                "batch": null,
                "status": null,
                "availableStartTime": null,
                "availableEndTime": null
            },
            {
                "macAddress": null,
                "deviceId": null,
                "x": 3,
                "y": 3,
                "type": "ROAD",
                "batch": null,
                "status": null,
                "availableStartTime": null,
                "availableEndTime": null
            },
            {
                "macAddress": null,
                "deviceId": null,
                "x": 4,
                "y": 3,
                "type": "ROAD",
                "batch": null,
                "status": null,
                "availableStartTime": null,
                "availableEndTime": null
            },
            {
                "macAddress": null,
                "deviceId": null,
                "x": 5,
                "y": 3,
                "type": "ROAD",
                "batch": null,
                "status": null,
                "availableStartTime": null,
                "availableEndTime": null
            },
            {
                "macAddress": null,
                "deviceId": null,
                "x": 6,
                "y": 3,
                "type": "ROAD",
                "batch": null,
                "status": null,
                "availableStartTime": null,
                "availableEndTime": null
            },
            {
                "macAddress": null,
                "deviceId": null,
                "x": 7,
                "y": 3,
                "type": "ROAD",
                "batch": null,
                "status": null,
                "availableStartTime": null,
                "availableEndTime": null
            }
        ],
        [
            {
                "macAddress": null,
                "deviceId": null,
                "x": 0,
                "y": 4,
                "type": "ROAD",
                "batch": null,
                "status": null,
                "availableStartTime": null,
                "availableEndTime": null
            },
            {
                "macAddress": null,
                "deviceId": null,
                "x": 1,
                "y": 4,
                "type": "ROAD",
                "batch": null,
                "status": null,
                "availableStartTime": null,
                "availableEndTime": null
            },
            {
                "macAddress": null,
                "deviceId": null,
                "x": 2,
                "y": 4,
                "type": "ROAD",
                "batch": null,
                "status": null,
                "availableStartTime": null,
                "availableEndTime": null
            },
            {
                "macAddress": null,
                "deviceId": null,
                "x": 3,
                "y": 4,
                "type": "ROAD",
                "batch": null,
                "status": null,
                "availableStartTime": null,
                "availableEndTime": null
            },
            {
                "macAddress": null,
                "deviceId": null,
                "x": 4,
                "y": 4,
                "type": "ROAD",
                "batch": null,
                "status": null,
                "availableStartTime": null,
                "availableEndTime": null
            },
            {
                "macAddress": null,
                "deviceId": null,
                "x": 5,
                "y": 4,
                "type": "ROAD",
                "batch": null,
                "status": null,
                "availableStartTime": null,
                "availableEndTime": null
            },
            {
                "macAddress": null,
                "deviceId": null,
                "x": 6,
                "y": 4,
                "type": "ROAD",
                "batch": null,
                "status": null,
                "availableStartTime": null,
                "availableEndTime": null
            },
            {
                "macAddress": null,
                "deviceId": null,
                "x": 7,
                "y": 4,
                "type": "ROAD",
                "batch": null,
                "status": null,
                "availableStartTime": null,
                "availableEndTime": null
            }
        ],
        [
            {
                "macAddress": null,
                "deviceId": null,
                "x": 0,
                "y": 5,
                "type": "ROAD",
                "batch": null,
                "status": null,
                "availableStartTime": null,
                "availableEndTime": null
            },
            {
                "macAddress": null,
                "deviceId": null,
                "x": 1,
                "y": 5,
                "type": "ROAD",
                "batch": null,
                "status": null,
                "availableStartTime": null,
                "availableEndTime": null
            },
            {
                "macAddress": null,
                "deviceId": null,
                "x": 2,
                "y": 5,
                "type": "ROAD",
                "batch": null,
                "status": null,
                "availableStartTime": null,
                "availableEndTime": null
            },
            {
                "macAddress": null,
                "deviceId": null,
                "x": 3,
                "y": 5,
                "type": "ROAD",
                "batch": null,
                "status": null,
                "availableStartTime": null,
                "availableEndTime": null
            },
            {
                "macAddress": null,
                "deviceId": null,
                "x": 4,
                "y": 5,
                "type": "ROAD",
                "batch": null,
                "status": null,
                "availableStartTime": null,
                "availableEndTime": null
            },
            {
                "macAddress": null,
                "deviceId": null,
                "x": 5,
                "y": 5,
                "type": "ROAD",
                "batch": null,
                "status": null,
                "availableStartTime": null,
                "availableEndTime": null
            },
            {
                "macAddress": null,
                "deviceId": null,
                "x": 6,
                "y": 5,
                "type": "ROAD",
                "batch": null,
                "status": null,
                "availableStartTime": null,
                "availableEndTime": null
            },
            {
                "macAddress": null,
                "deviceId": null,
                "x": 7,
                "y": 5,
                "type": "ROAD",
                "batch": null,
                "status": null,
                "availableStartTime": null,
                "availableEndTime": null
            }
        ],
        [
            {
                "macAddress": null,
                "deviceId": null,
                "x": 0,
                "y": 6,
                "type": "ROAD",
                "batch": null,
                "status": null,
                "availableStartTime": null,
                "availableEndTime": null
            },
            {
                "macAddress": null,
                "deviceId": null,
                "x": 1,
                "y": 6,
                "type": "ROAD",
                "batch": null,
                "status": null,
                "availableStartTime": null,
                "availableEndTime": null
            },
            {
                "macAddress": null,
                "deviceId": null,
                "x": 2,
                "y": 6,
                "type": "ROAD",
                "batch": null,
                "status": null,
                "availableStartTime": null,
                "availableEndTime": null
            },
            {
                "macAddress": null,
                "deviceId": null,
                "x": 3,
                "y": 6,
                "type": "ROAD",
                "batch": null,
                "status": null,
                "availableStartTime": null,
                "availableEndTime": null
            },
            {
                "macAddress": null,
                "deviceId": null,
                "x": 4,
                "y": 6,
                "type": "ROAD",
                "batch": null,
                "status": null,
                "availableStartTime": null,
                "availableEndTime": null
            },
            {
                "macAddress": null,
                "deviceId": null,
                "x": 5,
                "y": 6,
                "type": "ROAD",
                "batch": null,
                "status": null,
                "availableStartTime": null,
                "availableEndTime": null
            },
            {
                "macAddress": null,
                "deviceId": null,
                "x": 6,
                "y": 6,
                "type": "ROAD",
                "batch": null,
                "status": null,
                "availableStartTime": null,
                "availableEndTime": null
            },
            {
                "macAddress": null,
                "deviceId": null,
                "x": 7,
                "y": 6,
                "type": "ROAD",
                "batch": null,
                "status": null,
                "availableStartTime": null,
                "availableEndTime": null
            }
        ],
        [
            {
                "macAddress": null,
                "deviceId": null,
                "x": 0,
                "y": 7,
                "type": "ROAD",
                "batch": null,
                "status": null,
                "availableStartTime": null,
                "availableEndTime": null
            },
            {
                "macAddress": null,
                "deviceId": null,
                "x": 1,
                "y": 7,
                "type": "ROAD",
                "batch": null,
                "status": null,
                "availableStartTime": null,
                "availableEndTime": null
            },
            {
                "macAddress": null,
                "deviceId": null,
                "x": 2,
                "y": 7,
                "type": "ROAD",
                "batch": null,
                "status": null,
                "availableStartTime": null,
                "availableEndTime": null
            },
            {
                "macAddress": null,
                "deviceId": null,
                "x": 3,
                "y": 7,
                "type": "ROAD",
                "batch": null,
                "status": null,
                "availableStartTime": null,
                "availableEndTime": null
            },
            {
                "macAddress": null,
                "deviceId": null,
                "x": 4,
                "y": 7,
                "type": "ROAD",
                "batch": null,
                "status": null,
                "availableStartTime": null,
                "availableEndTime": null
            },
            {
                "macAddress": null,
                "deviceId": null,
                "x": 5,
                "y": 7,
                "type": "ROAD",
                "batch": null,
                "status": null,
                "availableStartTime": null,
                "availableEndTime": null
            },
            {
                "macAddress": null,
                "deviceId": null,
                "x": 6,
                "y": 7,
                "type": "ROAD",
                "batch": null,
                "status": null,
                "availableStartTime": null,
                "availableEndTime": null
            },
            {
                "macAddress": null,
                "deviceId": null,
                "x": 7,
                "y": 7,
                "type": "DEVICE",
                "batch": "NORTH",
                "status": "WAITING",
                "availableStartTime": null,
                "availableEndTime": null
            }
        ]
      ],
      maxX: 0,
      maxY: 0,
      selectedLot: null,
      selectedIcon: selectedIcon,
    };
  },
  computed: {
    id() {
      return this.$route.query.id;
    },
    authToken() {
      return this.$route.query.authToken;
    },
  },
  methods: {
    getId(deviceId) {  
      return deviceId ? deviceId.split('-').slice(1, 3).join('-') : null;
    },
    formatAvailableTime(start, end) {
      if (start && end) {
        return `${start.slice(0, 5)} ~ ${end.slice(0, 5)}`;
      } else {
        return "";  
      }
    },
    handleSelect(lot) {
      if (lot.type === "DEVICE" && lot.status === 'WAITING') {
        this.selectedLot = lot;
      }
    },
    isSelectedLot(lot) {
      return this.selectedLot && this.selectedLot.x === lot.x && this.selectedLot.y === lot.y;
    },
    lotIcon(lot) {
      if (lot.type === 'DEVICE') {
        if (lot.status === 'WAITING') {
          switch (lot.batch) {
            case 'EAST':
              return eastIcon;
            case 'WEST':
              return westIcon;
            case 'SOUTH':
              return southIcon;
            case 'NORTH':
              return northIcon;
            default:
              return null;
          }
        } else {
          return noIcon;
        } 
      } else if (lot.type === 'SEAT') {
        return noIcon;  // SEAT일 때 예약 불가 아이콘
      } else if (lot.type === 'ROAD') {
        return null; // ROAD는 아이콘 없음
      }
      return null;
    },

    sendToAndroidApp() {
      if (this.selectedLot) {
        window.AndroidApp.receiveLotInfo(JSON.stringify(this.selectedLot));
      } else {
        Swal.fire({
          icon:'warning',
          html: `주차자리를 선택해주세요.`,
          confirmButtonText: `확인`,
          confirmButtonColor: '#5B67EC',
        });
      }
    },
    calculateGridSize() {
      let maxX = 0;
      let maxY = 0;

      this.parkingLot.flat().forEach((lot) => {
        if (lot.x > maxX) maxX = lot.x;
        if (lot.y > maxY) maxY = lot.y;
      });

      this.maxX = maxX + 1;  // 열 개수
      this.maxY = maxY + 1;  // 행 개수
    },
    // 다른 메서드들...
  },
  mounted() {
    this.calculateGridSize();  // 그리드 크기 계산
    window.sendToAndroidApp = this.sendToAndroidApp;
  },
};
</script>

<style scoped>
/* 여기에 필요한 스타일 추가 */
.swal-icon img{
  width: 20px;
  height: 120px;
}
.container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
}

.seat-description {
  padding: 1rem;
}
.seat-description img {
  width: 2rem;
  height: 2rem;
}

.seat-description a {
  font-size: .8rem;
  margin-left: .2rem;
  margin-right: 1rem;
}

.seat-content {
  width: 100vw;
  height: fit-content;
  overflow: auto; /* 스크롤 활성화 */
  background: whitesmoke;
}

.grid-container {
  display: grid;
  width: 100%;
  height: fit-content;
  padding: 5px;
}

.grid-item {
  min-height: 80px; 
  min-width: 80px; 
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.item-src {
  height: 95%; /* 각 칸의 높이 */
  width: 95%; /* 각 칸의 너비 */
}

.lotDiv {
  display: inline-block;
}

.selected-overlay {
  position: absolute;
  top: 0;  /* 상단에 위치 */
  left: 0;  /* 좌측에 위치 */
  height: 100%;  /* grid-item 크기에 맞게 조정 */
  width: 100%;  /* grid-item 크기에 맞게 조정 */
  z-index: 2;
}

.item-desc {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  top: 0;  /* 상단에 위치 */
  left: 0;  /* 좌측에 위치 */
  height: 100%;  /* grid-item 크기에 맞게 조정 */
  width: 100%;  /* grid-item 크기에 맞게 조정 */
  z-index:1;
  padding: .3rem;
}

.desc-text{
  text-align: center;
  font-size: .6rem;
}
</style>
